import React, { useState, useContext, useEffect } from 'react';
import {navigate} from "@reach/router"
import _ from 'lodash';
import FloatingButton from '../layout/FloatingButton';
import {Layout, Fixed, Flex} from 'react-layout-pane';

import Header from '../layout/Header';
import { useTranslate } from 'react-polyglot';
import './CustomLoaderLayout.css';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import CustomLoaderMenu from '../CustomLoaderMenu/CustomLoaderMenu';
import CustomLoaderSaveChanges from '../CustomLoaderSaveChanges/CustomLoaderSaveChanges';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import CheckPermision from '../checkPermision/checkPermision';
import { userGroupsObject } from '../../utils/userGroups';

function CustomLoaderLayout(props) {
    const {editedScreen, setEditedScreen } = useContext(CustomLoaderContext);

    const t = useTranslate();
       
    const goHome = () => {
        navigate('/');
    }

    return (
        <Layout type="column">

            <Flex>
                <Layout type="row">

                    <Fixed className="header">
                        <Header hideSearch='true'/>
                    </Fixed>

                    <Fixed className="sidebar"> 
                        <div className='layout-title-container'>
                            <span className='filter-page-name go-back-button' onClick={goHome}>
                                <img alt='' className='sprite-toolbar-arrow-left' src='/images/arrow-left.svg'></img>{t('customLoaderLayout.back')}</span>
                        </div>
                        <CustomLoaderMenu/>
                        <CustomLoaderSaveChanges/>
                    </Fixed>

                    <Flex className="content custom-loader-layout">
                        <div className="content-container">                    
                            {props.children}
                        </div>
                        <FloatingButton/>
                    </Flex>
                    
                </Layout>
            </Flex>
        </Layout>
    )
    
}

export default CustomLoaderLayout;
