import React, {createContext, useState} from 'react';
import getLocale from '../utils/locale';

export const CustomLoaderContext = createContext();

const METADATA_VERSION_1 = 1
const METADATA_VERSION_2 = 2

const LATEST_METADATA_VERSION = METADATA_VERSION_2;

const EDITOR_INITIAL_SCREEN = "loader"

const DEFAULT_COLORS = {
    BLACK: '#000000',
    BACKGROUND_GRAY: '#ECE7E7',
    CAMON_BLUE: '#3434E0',
}

const CustomLoaderProvider = (props) => { 

    // options: loader, qr, qtWTW
    const [editedScreen, setEditedScreen] = useState(EDITOR_INITIAL_SCREEN);
    const [isLegacyMetadata, setIsLegacyMetadata] = useState(false)

    const [language, setLanguage] = useState(getLocale());
    const [changesSaved, setChangesSaved] = useState(true)

    const DEFAULT_FAVICON = "https://cdn-studio.camonapp.com/camonapp/img/favicon.ico"
    const DEFAULT_DOCUMENT_TITLE = "CamOn Immersive"

    const [generalSettings, setGeneralSettings] = useState({
        documentTitle: DEFAULT_DOCUMENT_TITLE,
        favicon: {
            reset: false,
            local: DEFAULT_FAVICON,
            file: undefined,
            url: DEFAULT_FAVICON
        },
    })
        

    const [loadingScreenSettings, setLoadingScreenSettings] = useState({
        iconColor: DEFAULT_COLORS.CAMON_BLUE,
        textColor: DEFAULT_COLORS.BLACK,
        headerFont: 'CoaFont',
        headerImage: {
            default: true,
            reset: false,
            local: undefined,
            file: undefined,
            url: ''
        },
        loadingBarColor: DEFAULT_COLORS.CAMON_BLUE,
        background: {
            color: DEFAULT_COLORS.BACKGROUND_GRAY,
            isGrid: true,
            isGradiant: false,
            gradiantColor: DEFAULT_COLORS.BACKGROUND_GRAY,
            reset: false,
            local: undefined,
            file: undefined,
            url: ""
        },
        footerThemeColor: 'black',
        footerImage: {
            reset: false,
            local: undefined,
            file: undefined,
            url: ''
        },
        centerImage: {
            reset: false,
            local: undefined,
            file: undefined,
            url: ''
        },
    })

    const [qrMvarScreenSettings, setQrMvarScreenSettings] = useState({
        fonts: {
            textColor: DEFAULT_COLORS.BLACK,
            family: 'CoaFont',
        },
        brandImage: {
            reset: false,
            local: undefined,
            file: undefined,
            url: ''
        },
        background: {
            color: DEFAULT_COLORS.BACKGROUND_GRAY,
            isGrid: true,
            isGradiant: false,
            gradiantColor: DEFAULT_COLORS.BACKGROUND_GRAY,
            reset: false,
            local: undefined,
            file: undefined,
            url: ""
        },
        footerThemeColor: 'black',
        footerImage: {
            reset: false,
            local: undefined,
            file: undefined,
            url: ''
        },
        button: {
            backgroundColor: DEFAULT_COLORS.CAMON_BLUE,
            textColor: '#FFFFFF'
        }
    });

    const [qrWTWScreenSettings, setQrWTWScreenSettings] = useState({
        fonts: {
            textColor: DEFAULT_COLORS.CAMON_BLUE,
            family: 'CoaFont',
        },
        brandImage: {
            reset: false,
            local: undefined,
            file: undefined,
            url: ''
        },
        background: {
            color: DEFAULT_COLORS.BACKGROUND_GRAY,
            isGrid: true,
            isGradiant: false,
            gradiantColor: DEFAULT_COLORS.BACKGROUND_GRAY,
            reset: false,
            local: undefined,
            file: undefined,
            url: ''
        },
        footerThemeColor: 'black',
        footerImage: {
            reset: false,
            local: undefined,
            file: undefined,
            url: ''
        },
    })

    const mergeDeep = (target, source) => {
        for (const key in source) {
            if (source[key] instanceof File) {
                // Si es un objeto tipo File, simplemente lo asignamos
                target[key] = source[key];
            } else if (source[key] instanceof Object && !(source[key] instanceof Array)) {
                // Merge profundo para objetos
                target[key] = mergeDeep(target[key] || {}, source[key]);
            } else {
                // Asignación directa para valores primitivos
                target[key] = source[key];
            }
        }
        return target;
    };

    const updateGeneralSettings = (newState) => {
        setGeneralSettings(s => {
            return mergeDeep({...s}, newState);      
        })
    }
    
    const updateLoadingSettings = (newState) => {
        setLoadingScreenSettings(s => {
            return mergeDeep({...s}, newState);      
        })
    }

    const updateQRMvarSettings = (newState) => {
        setQrMvarScreenSettings(s => {
            return mergeDeep({...s}, newState);
        })
    }

    const updateQRWTWSettings = (newState) => {
        setQrWTWScreenSettings(s => {
            return mergeDeep({...s}, newState);
        })
    }

    const setData = (jsonData) => {
        const styleMetadataVersion = jsonData.version || METADATA_VERSION_1;

        setIsLegacyMetadata(styleMetadataVersion !== LATEST_METADATA_VERSION)

        // v1
        const loaderIconColor = jsonData.iconColor == "null" ? DEFAULT_COLORS.CAMON_BLUE : jsonData.iconColor;
        const loaderTextColor = jsonData.textColor;
        const loaderLoadingBarColor = jsonData.loadingBarColor;
        const loaderHeaderFont = jsonData.headerFont || 'CoaFont';
        const loaderBackgroundColor = jsonData.backgroundColor;
        const loaderBackgroundGradiantColor = jsonData.backgroundGradiantColor;
        const loaderBackgroundIsGrid = jsonData.isGrid == 'true' ? true : false;
        const loaderBackgroundIsGradiant = jsonData.isGradiant == 'true' ? true : false;
        const loaderBackgroundLocal = jsonData.backgroundURL;
        const loaderCenterLocal = jsonData.centerLogoURL || '';
        const loaderFooterThemeColor = jsonData.footerThemeColor || 'black';

        if (styleMetadataVersion === METADATA_VERSION_1) {
            updateLoadingSettings({
                iconColor: loaderIconColor || loadingScreenSettings.iconColor,
                textColor: loaderTextColor || loadingScreenSettings.textColor,
                loadingBarColor: loaderLoadingBarColor || loadingScreenSettings.loadingBarColor,
                headerFont: loaderHeaderFont || loadingScreenSettings.headerFont,
                headerImage: {
                    default: jsonData.hasDefaultLogo === "true" || jsonData.hasDefaultLogo === true ? true : false,
                    reset: false,
                    local: jsonData.logoURL || loadingScreenSettings.headerImage.url,
                    url: jsonData.logoURL || loadingScreenSettings.headerImage.url,
                },
                background: {
                    color: loaderBackgroundColor || loadingScreenSettings.background.color,
                    gradiantColor: loaderBackgroundGradiantColor || loadingScreenSettings.background.gradiantColor,
                    isGrid: loaderBackgroundIsGrid  || loadingScreenSettings.background.isGrid,
                    isGradiant: loaderBackgroundIsGradiant  || loadingScreenSettings.background.isGradiant,
                    local: loaderBackgroundLocal  || loadingScreenSettings.background.url,
                    url: loaderBackgroundLocal || loadingScreenSettings.background.url,
                    reset: false,
                },
                footerThemeColor: loaderFooterThemeColor,
                centerImage: {
                    local: loaderCenterLocal || loadingScreenSettings.centerImage.url,
                    reset: false
                }
            })
            updateQRMvarSettings({
                fonts: {
                    textColor: loaderTextColor ,
                    family:  loaderHeaderFont ,
                },
                brandImage: {
                    reset: false,
                    local: undefined,
                    file: undefined,
                    url: ''
                },
                background: {
                    color: loaderBackgroundColor,
                    isGrid: loaderBackgroundIsGrid  || qrMvarScreenSettings.background.isGrid,
                    isGradiant: loaderBackgroundIsGradiant || qrMvarScreenSettings.background.isGradiant,
                    gradiantColor: loaderBackgroundGradiantColor,
                    reset: false,
                    local: undefined,
                    file: undefined,
                    url: ''
                },
                footerThemeColor: loaderFooterThemeColor,
                footerImage: {
                    reset: false,
                    local: undefined,
                    file: undefined,
                    url: ''
                },
                button: {
                    backgroundColor: jsonData.qrScreen ? jsonData.qrScreen.buttonBgColor : DEFAULT_COLORS.CAMON_BLUE,
                    textColor: jsonData.qrScreen ? jsonData.qrScreen.buttonTextColor : '#FFFFFF'
                }
            })
            updateQRWTWSettings({
                fonts: {
                    textColor: loaderTextColor ,
                    family:  loaderHeaderFont ,
                },
                brandImage: {
                    reset: false,
                    local: undefined,
                    file: undefined,
                    url: ''
                },
                background: {
                    color: loaderBackgroundColor ,
                    isGrid: loaderBackgroundIsGrid  || qrWTWScreenSettings.background.isGradiant,
                    isGradiant: loaderBackgroundIsGradiant   || qrWTWScreenSettings.background.isGradiant,
                    gradiantColor: loaderBackgroundGradiantColor ,
                    reset: false,
                    local: undefined,
                    file: undefined,
                    url: ''
                },
                footerThemeColor: loaderFooterThemeColor,
                footerImage: {
                    reset: false,
                    local: undefined,
                    file: undefined,
                    url: ''
                },
            })
        } else {
            updateGeneralSettings({
                documentTitle: jsonData.general && jsonData.general.documentTitle ? jsonData.general.documentTitle : DEFAULT_DOCUMENT_TITLE,
                favicon: {
                    reset: false,
                    url: jsonData.general ? jsonData.general.favicon.url : DEFAULT_FAVICON,
                    local: jsonData.general ? jsonData.general.favicon.url : DEFAULT_FAVICON,
                }
            })
            updateLoadingSettings({
                iconColor: jsonData.loader.iconColor === "null" ? DEFAULT_COLORS.CAMON_BLUE : jsonData.loader.iconColor,
                textColor: jsonData.loader.textColor,
                loadingBarColor: jsonData.loader.loadingBarColor,
                headerFont: jsonData.loader.headerFont,
                headerImage: {
                    default: jsonData.loader.headerImage.default === "true" || jsonData.loader.headerImage.default === true ? true : false,
                    reset: false,
                    local: jsonData.loader.headerImage.url,
                    url: jsonData.loader.headerImage.url,
                },
                background: {
                    color: jsonData.loader.background.color,
                    gradiantColor: jsonData.loader.background.gradiantColor,
                    isGrid: jsonData.loader.background.url ? false : (jsonData.loader.background.isGrid === 'true'),
                    isGradiant: jsonData.loader.background.url ? false : (jsonData.loader.background.isGradiant === 'true'),
                    local: jsonData.loader.background.url,
                    url: jsonData.loader.background.url,
                    reset: false,
                },
                footerThemeColor: jsonData.loader.footerThemeColor,
                footerImage: {
                    local: jsonData.loader.footerImage.url,
                    reset: false
                },
                centerImage: {
                    local: jsonData.loader.centerImage.url,
                    reset: false
                }
            })
            updateQRMvarSettings({
                fonts: {
                    textColor: jsonData.qrMvar.fonts.textColor,
                    family:  jsonData.qrMvar.fonts.family,
                },
                brandImage: {
                    reset: false,
                    local: jsonData.qrMvar.brandImage.url,
                    file: undefined,
                    url: ''
                },
                background: {
                    color: jsonData.qrMvar.background.color,
                    isGrid: jsonData.qrMvar.background.url ? false : (jsonData.qrMvar.background.isGrid === 'true'),
                    isGradiant: jsonData.qrMvar.background.url ? false : (jsonData.qrMvar.background.isGradiant === 'true'),
                    gradiantColor: jsonData.qrMvar.background.gradiantColor,
                    reset: false,
                    local: jsonData.qrMvar.background.url,
                    file: undefined,
                    url: jsonData.qrMvar.background.url
                },
                footerThemeColor: jsonData.qrMvar.footerThemeColor || 'black',
                footerImage: {
                    reset: false,
                    local: jsonData.qrMvar.footerImage.url,
                    file: undefined,
                    url: jsonData.qrMvar.footerImage.url
                },
                button: {
                    backgroundColor: jsonData.qrMvar.button.backgroundColor,
                    textColor: jsonData.qrMvar.button.textColor
                }
            })
            updateQRWTWSettings({
                fonts: {
                    textColor: jsonData.qrWTW.fonts.textColor,
                    family:  jsonData.qrWTW.fonts.family,
                },
                brandImage: {
                    reset: false,
                    local: jsonData.qrWTW.brandImage.url,
                    file: undefined,
                    url: ''
                },
                background: {
                    color: jsonData.qrWTW.background.color,
                    isGrid: jsonData.qrWTW.background.url ? false : (jsonData.qrWTW.background.isGrid === 'true'),
                    isGradiant: jsonData.qrWTW.background.url ? false : (jsonData.qrWTW.background.isGradiant === 'true'),
                    gradiantColor: jsonData.qrWTW.background.gradiantColor,
                    reset: false,
                    local: jsonData.qrWTW.background.url,
                    file: undefined,
                    url: jsonData.qrWTW.background.url
                },
                footerThemeColor: jsonData.qrWTW.footerThemeColor || 'black',
                footerImage: {
                    reset: false,
                    local: jsonData.qrWTW.footerImage.url,
                    file: undefined,
                    url: ''
                },
            })

        }
    }

    return (<CustomLoaderContext.Provider
        value={{
            editedScreen, setEditedScreen,
            language, setLanguage,
            changesSaved, setChangesSaved,
            isLegacyMetadata, setIsLegacyMetadata,
            setData,

            DEFAULT_FAVICON,

            generalSettings, setGeneralSettings,
            updateGeneralSettings,

            loadingScreenSettings, setLoadingScreenSettings,
            updateLoadingSettings,

            qrMvarScreenSettings, setQrMvarScreenSettings,
            updateQRMvarSettings,

            qrWTWScreenSettings, setQrWTWScreenSettings,
            updateQRWTWSettings,

        }}>
            {props.children}
        </CustomLoaderContext.Provider>
    );
};

export default CustomLoaderProvider;
