import React, { useContext, useRef } from "react";
import "@rmwc/badge/badge.css";
import "../CustomLoaderPreview.css";
import { CustomLoaderContext } from "../../../context/CustomLoaderContext";
import { getFontFamily } from "../../CustomLoaderMenu/availableFonts";


function GeneralPreview(props) {
  const { generalSettings } = useContext(CustomLoaderContext);

  return (<>
    {
      props.show &&
      <div className="previewer">
        <div className="browser-simulator">
          <div className="browser-title">
            <img
              src={generalSettings.favicon.local ? generalSettings.favicon.local : generalSettings.favicon.url}
              alt="Favicon"
              className="favicon"
            />
            <span className="title">{generalSettings.documentTitle}</span>
            <div className="window-controls">
              <div className="window-button minimize" title="Minimizar"></div>
              <div className="window-button restore" title="Restaurar"></div>
              <div className="window-button close" title="Cerrar"></div>
          </div>
          </div>
          <div className="browser-address-bar">
            <input
              type="text"
              className="address-input"
              value="https://vr.camonapp.com/my-experience"
              readOnly
            />
          </div>
          <div className="browser-body">
            <p>Contenido de la ventana del navegador.</p>
          </div>
        </div>
      </div>
    }
  </>
  );
}

export default GeneralPreview;
