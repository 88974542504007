import React, {useState, useContext, useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import '@rmwc/badge/badge.css';
import './CustomLoaderMenu.css';

import '@material/textfield/dist/mdc.textfield.css';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import AlertDialog from '../general/AlertDialog';

import { AVAILABLE_FONTS } from './availableFonts';
import CheckPermision from '../checkPermision/checkPermision';
import { userGroupsObject } from '../../utils/userGroups';
import LoadingScreen from './menus/LoadingScreen';
import QRScreenMVAR from './menus/QRScreenMVAR';
import QRScreenWTW from './menus/QRScreenWTW';
import General from './menus/General';

const SCREENS_VALUES = [
    'loader',
    'qr',
    'qrWTW',
    'general',
]

function CustomLoaderMenu(props) {

    const t = useTranslate();
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })
    const {
           isLegacyMetadata, setIsLegacyMetadata,
           editedScreen, setEditedScreen,
        } = useContext(CustomLoaderContext);

    const [collapse, setCollapse] = useState(false);
  

    const handleAlert = () => {
        if (isLegacyMetadata) setIsLegacyMetadata(false);
        console.log(" ")
    }

    useEffect(() => {
        if (isLegacyMetadata) {
            setOpen({
                title: "Advertencia",
                subtitle: "Los estilos de este proyecto fueron generados con una versión anterior del editor." +
                        "Algunas configuraciones pueden variar respecto al original." +
                        "Edite y guarde los cambios para no volver a ver este mensaje.",
                isOpen: true
            });
        }
    }, [isLegacyMetadata])

    useEffect(() => {
        // Create a promise for each font
        const fontPromises = AVAILABLE_FONTS.flatMap(fontFamily => {
            return fontFamily.urls.map(url => new FontFace(fontFamily.name, `url(${url})`).load());
        });

        // Wait for all fonts to load
        Promise.all(fontPromises)
            .then(fonts => {
                fonts.forEach(font => {
                    document.fonts.add(font);
                });
            })
            .catch(error => {
                console.error('Error loading fonts:', error);
            });

    },[])

    return (
        <div className={collapse ? 'sidebar-container collapsed' : 'sidebar-container custom-loader-sidebar'}>
            <CheckPermision
                component={
                    <div className='screen-selector-container'>
                        <select
                            className="screen-selector-select"
                            value={editedScreen}
                            style={{fontFamily: 'Roboto', fontWeight: 'normal'}}
                            onChange={(e) => setEditedScreen(e.target.value)}
                            label=""
                        >
                            {
                                SCREENS_VALUES.map((screen_item) => {
                                    return (
                                        <option 
                                            key={`screen-${screen_item}`} 
                                            value={screen_item}
                                            style={{ fontFamily: 'Roboto', fontWeight: 'normal', fontSize: '16px', padding: '8px' }}
                                        >
                                            {t("customLoaderSidebar.menu.screenOptions." + screen_item)}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                }
                groups={[
                    userGroupsObject.CamonappAdminUsers,
                    userGroupsObject.CamonappSalesUsers,
                    userGroupsObject.CamonappUsers,
                    userGroupsObject.CamonappUsersCommon,
                    userGroupsObject.CompanyUsers
                ]}
            ></CheckPermision>

            {
                editedScreen === 'general' &&
                <General {...props} open setOpen={setOpen}></General>
            }
            { 
                editedScreen === 'loader' &&
                <LoadingScreen {...props} open setOpen={setOpen}/>
            }
            { 
                editedScreen === 'qr' &&
                <QRScreenMVAR {...props} open setOpen={setOpen}/>
            }
            { 
                editedScreen === 'qrWTW' &&
                <QRScreenWTW {...props} open setOpen={setOpen}/>
            }
           
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                functionHandler={handleAlert}
                buttonMessage='OK'
            />     
        </div>
    );
}

export default CustomLoaderMenu;
