import React, {useState, useContext, useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import '@rmwc/badge/badge.css';
import '../CustomLoaderMenu.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ColorPicker from '../../colorPicker/ColorPicker';
import InputFileDragAndDrop from '../../inputFileDragAndDrop/InputFileDragAndDrop'
import '@material/textfield/dist/mdc.textfield.css';
import { CustomLoaderContext } from '../../../context/CustomLoaderContext';
import {
    FontSizeOutlined,
    ClockCircleOutlined,
    DownloadOutlined,
    PictureOutlined
  } from '@ant-design/icons';
import { Checkbox } from '@mui/material';

import { AVAILABLE_FONTS, getFontFamily } from '../availableFonts';


function LoadingScreen(props) {

    const t = useTranslate();
    const {
        open, setOpen,
    } = props

    const {
           setChangesSaved,
           updateLoadingSettings,
           loadingScreenSettings,
        } = useContext(CustomLoaderContext);

    const [textSettingsOpen, setTextSettingsOpen] = useState(false);
    const [logoSettingsOpen, setLogoSettingsOpen] = useState(false);
    const [loadingBarSettingsOpen, setLoadingBarSettingsOpen] = useState(false);
    const [footerSettingsOpen, setFooterSettingsOpen] = useState(false);
    const [centerLogoSettingsOpen, setCenterLogoSettingsOpen] = useState(false);
    const [backgroundSettingsOpen, setBackgroundSettingsOpen] = useState(false);


    // footer logo
    const flThemeColors = ["black", "white"];

    const closeAll = () => {
        setTextSettingsOpen(false);
        setLogoSettingsOpen(false);
        setLoadingBarSettingsOpen(false);
        setCenterLogoSettingsOpen(false);
        setFooterSettingsOpen(false);
        setBackgroundSettingsOpen(false);
    }

    const handleMenuClick = (currentState, openSetter) => {
        closeAll();
        openSetter(!currentState)
    }
    
    const handleUpdateSettings = (newSettings) => {
        setChangesSaved(false);
        updateLoadingSettings(newSettings)
    }

    useEffect(() => {
        if (loadingScreenSettings.background.file) {
            updateLoadingSettings({
                background: {
                    isGradiant: false,
                    isGrid: false
                }
            })
        }
    }, [loadingScreenSettings.background.file])

    return (
        <List>   
            <ListItem button onClick={() => handleMenuClick(logoSettingsOpen, setLogoSettingsOpen)} className={logoSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <PictureOutlined style={{marginLeft: '8px', marginRight: '8px', width: '16px', height: '16px'}}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.loader.header')} />
            </ListItem>
            <Collapse in={logoSettingsOpen} unmountOnExit>
                <ListItem disabled={!loadingScreenSettings.headerImage.default}>
                    <ColorPicker
                        disabled={!loadingScreenSettings.headerImage.default}
                        defaultColor={loadingScreenSettings.iconColor}
                        handleColor={(iconColor)=>handleUpdateSettings({iconColor})}></ColorPicker>
                </ListItem>
                <ListItem>
                    <InputFileDragAndDrop
                        id="logoFile"
                        acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                        setOpen={setOpen}
                        handleURL={(url) => handleUpdateSettings({headerImage: {url}})}
                        handleFile={(file) => handleUpdateSettings({headerImage: {file}})}
                        defaultFile={loadingScreenSettings.headerImage.default ? "" : loadingScreenSettings.headerImage.local}
                        onFileLoaded={(filePath) => {
                            handleUpdateSettings({
                                headerImage: {
                                    default: false,
                                    //file: file => file,
                                    local: filePath,
                                    reset: false,
                                }
                            })
                        }}
                        onQuitFile={() => {
                            handleUpdateSettings({
                                headerImage: {
                                    reset: true,
                                    default: true,
                                    file: undefined,
                                    local: undefined,
                                    url: '',
                                }
                            })
                        }}
                    ></InputFileDragAndDrop>
                </ListItem>
            </Collapse>

            <ListItem button onClick={() => handleMenuClick(textSettingsOpen, setTextSettingsOpen)} className={textSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <FontSizeOutlined style={{marginLeft: '8px', marginRight: '8px', width: '16px', height: '16px'}} />
                <ListItemText primary={t('customLoaderSidebar.menu.screens.loader.fonts')} />
            </ListItem>
            <Collapse in={textSettingsOpen}>
                <ListItem>
                    <ColorPicker
                        defaultColor={loadingScreenSettings.textColor}
                        handleColor={(textColor)=>handleUpdateSettings({textColor})}>
                    </ColorPicker>
                </ListItem>
                <ListItem className="text-field">
                    <select
                        className="select-header-font"
                        value={loadingScreenSettings.headerFont}
                        style={{
                            fontFamily: getFontFamily(loadingScreenSettings.headerFont),
                            fontWeight: 'normal'
                        }}
                        onChange={(e)=>handleUpdateSettings({headerFont: e.target.value})}
                        label=" "
                    >
                        {
                            AVAILABLE_FONTS.map((font) => {
                                return (
                                    <option 
                                        key={`font-${font.name}`} 
                                        value={font.name}
                                        style={{ fontFamily: font.normal.fontFamily, fontWeight: font.normal.fontWeight, fontSize: '16px', padding: '8px' }}
                                    >
                                        {font.name}
                                    </option>
                                )
                            })
                        }
                    </select>
                </ListItem>
            </Collapse>

            <ListItem button onClick={() => handleMenuClick(centerLogoSettingsOpen, setCenterLogoSettingsOpen)} className={centerLogoSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <PictureOutlined src={`/images/reports-hovered.svg`} style={{marginLeft: '8px', marginRight: '8px', width: '16px', height: '16px'}}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.loader.centerLogo')} />
            </ListItem>
            <Collapse in={centerLogoSettingsOpen} unmountOnExit>
                <ListItem>
                    <InputFileDragAndDrop
                        id="centerLogoFile"
                        acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                        setOpen={setOpen}
                        handleURL={(url)=>handleUpdateSettings({centerImage: {url}})}
                        handleFile={(file)=>handleUpdateSettings({centerImage: {file}})}
                        defaultFile={loadingScreenSettings.centerImage.local}
                        onFileLoaded={(filePath) => {
                            handleUpdateSettings({
                                centerImage: {
                                    local: filePath,
                                    //file: file => file,
                                    reset: false
                                }
                            })
                        }}
                        onQuitFile={() => {
                            handleUpdateSettings({
                                centerImage: {
                                    reset: true,
                                    file: undefined,
                                    local: undefined,
                                    url: '',
                                }
                            })
                        }}
                    ></InputFileDragAndDrop>
                </ListItem>
            </Collapse>

            <ListItem button onClick={() => handleMenuClick(loadingBarSettingsOpen, setLoadingBarSettingsOpen)} className={loadingBarSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <ClockCircleOutlined style={{marginLeft: '8px', marginRight: '8px', width: '16px', height: '16px'}}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.loader.loadingBar')} />
            </ListItem>
            <Collapse in={loadingBarSettingsOpen}>
                <ListItem>
                    <ColorPicker
                        defaultColor={loadingScreenSettings.loadingBarColor}
                        handleColor={(loadingBarColor)=>handleUpdateSettings({loadingBarColor})}>
                    </ColorPicker>
                </ListItem>
            </Collapse>

            <ListItem button onClick={() => handleMenuClick(footerSettingsOpen, setFooterSettingsOpen)} className={footerSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <DownloadOutlined style={{marginLeft: '8px', marginRight: '8px', width: '16px', height: '16px'}}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.loader.footer')} />
            </ListItem>
            <Collapse in={footerSettingsOpen} className='footer-container'>
                <ListItem className="text-field">
                    <select
                        className="select-footer-theme-color"
                        value={loadingScreenSettings.footerThemeColor}
                        onChange={(e)=>handleUpdateSettings({footerThemeColor: e.target.value})}
                        label=" "
                    >
                        {
                            flThemeColors.map((l) => {
                                return <option key={`theme-${l}`} value={l}>{t(`customLoaderSidebar.menu.screens.loader.${l}`)}</option>
                                
                            })
                        }
                    </select>
                </ListItem>
                <ListItem>
                    <InputFileDragAndDrop
                        id="loadingFooterLogo"
                        acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                        setOpen={setOpen}
                        handleURL={(url)=>handleUpdateSettings({footerImage: {url}})}
                        handleFile={(file)=>handleUpdateSettings({footerImage: {file}})}
                        defaultFile={loadingScreenSettings.footerImage.local}
                        onFileLoaded={(filePath) => {
                            handleUpdateSettings({
                                footerImage: {
                                    local: filePath,
                                    reset: false
                                }
                            })
                        }}
                        onQuitFile={() => {
                            handleUpdateSettings({
                                footerImage: {
                                    local: undefined,
                                    file: undefined,
                                    reset: true
                                }
                            })
                        }}
                    ></InputFileDragAndDrop>
                </ListItem>
            </Collapse>

            <ListItem button onClick={() => handleMenuClick(backgroundSettingsOpen, setBackgroundSettingsOpen)} className={backgroundSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <PictureOutlined src={`/images/reports-hovered.svg`} style={{
                                            marginLeft: '8px',
                                            marginRight: '8px',
                                            width: '16px',
                                            height: '16px'
                                        }}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.loader.background')} />
            </ListItem>
            <Collapse in={backgroundSettingsOpen} unmountOnExit>
                <ListItem>
                    <div className='background-item-container'>
                        <ColorPicker
                            disabled={loadingScreenSettings.background.local}
                            defaultColor={loadingScreenSettings.background.color}
                            handleColor={(color)=>handleUpdateSettings({background: {color}})}>
                        </ColorPicker>

                        { loadingScreenSettings.background.isGradiant &&
                            <ColorPicker
                                disabled={loadingScreenSettings.background.local}
                                defaultColor={loadingScreenSettings.background.gradiantColor}
                                handleColor={(gradiantColor)=>handleUpdateSettings({background: {gradiantColor}})}>
                            </ColorPicker>
                        }
                        <div>
                            <Checkbox
                                disabled={loadingScreenSettings.background.local}
                                checked={loadingScreenSettings.background.isGradiant}
                                onChange={(e)=>handleUpdateSettings({background: {isGradiant: e.target.checked}})}/> {t('customLoaderSidebar.menu.screens.loader.gradiant')}
                        </div>
                        <div>
                            <Checkbox
                                disabled={loadingScreenSettings.background.local}
                                checked={loadingScreenSettings.background.isGrid}
                                onChange={(e)=>handleUpdateSettings({background: {isGrid: e.target.checked}})}/>{t('customLoaderSidebar.menu.screens.loader.grid')}                            
                        </div>
                        <InputFileDragAndDrop
                            id="backgroundFile"
                            acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                            setOpen={setOpen}
                            handleURL={(url) => handleUpdateSettings({background: {url}})}
                            handleFile={(file) => handleUpdateSettings({background: {file}})}
                            defaultFile={loadingScreenSettings.background.local}
                            onFileLoaded={(filePath) => {
                                handleUpdateSettings({
                                    background: {
                                        local: filePath,
                                        //file: file => handleUpdateSettings({background: {file}}),
                                        reset: false,
                                        isGrid: false,
                                    }
                                })
                            }}
                            onQuitFile={() => {
                                handleUpdateSettings({
                                    background: {
                                        file: undefined,
                                        local: undefined,
                                        reset: true,
                                        isGrid: true,
                                    }
                                })
                            }}
                        ></InputFileDragAndDrop>
                    </div>
                </ListItem>
            </Collapse>
        </List>
    );
}

export default LoadingScreen;
