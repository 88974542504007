import React, { useEffect, useContext, Fragment } from 'react';
import CustomLoaderPreview from '../../components/CustomLoaderPreview';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import './CustomLoaderEditor.css';
import CustomLoaderService from '../../services/CustomLoader';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import Loading from '../../components/loading';


function CustomLoaderEditor() {

    const { showLoading, setShowLoading } = useContext(ShowLoadingContext)
    const campaignId = window.location.pathname.split('/')[3];
  
    const { setData } = useContext(CustomLoaderContext);

    useEffect( () => {
        getCustomLoaderConfig();
    }, []);

    async function getCustomLoaderConfig() {
        setShowLoading(true);
        (CustomLoaderService.getCustomLoaderConfig(campaignId))
        .then(response=> response.json())
        .then(data => {
            setData(data);
            setShowLoading(false);
        });
    }
    
    return (
        <Fragment>
            {showLoading && 
                <Loading/>
            }
            <div className="preview-container">
                <CustomLoaderPreview className="preview"/>
            </div>
        </Fragment>
    );
}

export default CustomLoaderEditor;