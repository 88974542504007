import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@rmwc/button';
import './InputFileDragAndDrop.scss'
import { useTranslate } from 'react-polyglot';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';

function InputFileDragAndDrop (props) {
    const [fileValue, setFileValue] = useState(false);
    const [inputFileValue, setInputFileValue] = useState('');
    const [filePath, setFilePath] = useState('');

    const {
        id,
        acceptedExtensions,
        onFileLoaded,
        onQuitFile,
        handleFile,
        defaultFile,
        handleURL,
        setBackgroundFile,
        setOpen,
    } = props;

    const { setChangesSaved } = useContext(CustomLoaderContext);
    const t = useTranslate();
    const inputId = id ? id : 'inputFile';

    const warningTextInvalidFormat = props.warningTextInvalidFormat || t('customLoaderLayout.formatImage')
    const infoTextValidFormats = props.infoTextValidFormats || t('dragAndDropFile.format') 

    const acceptedExtensionsList = acceptedExtensions.split(', ').map((ext) => ext.substr(1))

    useEffect(() => {
        if(filePath !== '') {
            let formatImage = inputFileValue.split(".");
            let formatImageParse = formatImage[formatImage.length-1];

            onFileLoaded(filePath);

            if(!acceptedExtensionsList.includes(formatImageParse)) {
                setOpen({
                    title: t('customLoaderLayout.error'),
                    subtitle: warningTextInvalidFormat,
                    isOpen: true
                });
                quitFile();
            }
        }
    },[filePath])

    function getFilePath(file) {
        if (file.files['0']) {
            const url = URL.createObjectURL(file.files[0]);
            setFilePath(url);
            handleFile(file.files['0']);
            handleURL(url);
        } else {
            setFileValue(false);
        }
    }

    function quitFile() {
        onQuitFile();
        setChangesSaved(false);
        setFilePath('');
        setFileValue(false);
        setInputFileValue('')
    }

    function triggerFile(e) {
        e.preventDefault();
        document.getElementById(inputId).click();
    }

    return (
        <div className='preview-logo'>
            <div className={'new-logo-preview' + (fileValue ? ' file-selected' : '')}
                 onClick={() => {
                    if (fileValue) {
                        document.getElementById(inputId).click();
                    }
                }}
            >
                <input id={inputId}
                       type="file"
                       name="image"
                       accept={acceptedExtensions}
                       style={{ display: 'none' }}
                       value={inputFileValue}
                       onChange={(e) => {
                             setInputFileValue(e.target.value);
                             setFileValue(true);
                             getFilePath(e.target);
                         }
                       }
                />
                {defaultFile ?
                    <img src={filePath ? filePath : defaultFile} alt={'fileValue'} className={'new-logo-preview-image'} />
                    :
                    <div className={'image-input'}>
                        <div className={'label-input-image'}>
                            <span>{ setBackgroundFile ? t('dragAndDropFile.textBackground') : t('dragAndDropFile.textHeader')}</span>
                        </div>
                        <div>
                            <Button outlined label={t('dragAndDropFile.add')} className={'new-logo-button'} onClick={triggerFile} />
                        </div>
                        <span className={'label-input-image'}>{infoTextValidFormats}</span>
                    </div>
                }
            </div>
            {
                defaultFile &&
                <Button className='quit-image' danger outlined onClick={() => quitFile()}>{t('dragAndDropFile.quit')}</Button>
            }
        </div>
    );
}

export default InputFileDragAndDrop;