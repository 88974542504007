import React, { useContext } from "react";
import "@rmwc/badge/badge.css";
import "./CustomLoaderPreview.css";
import { CustomLoaderContext } from "../../context/CustomLoaderContext";
import QRScreenMVARPreview from "./screens/QRScreenMVARPreview";
import LoadingScreenPreview from "./screens/LoadingScreenPreview";
import QRScreenWTWPreview from "./screens/QRScreenWTWPreview";
import GeneralPreview from "./screens/GeneralPreview";

function CustomLoaderPreview(props) {
  const { editedScreen } = useContext(CustomLoaderContext);

  return (
    <> 
      <LoadingScreenPreview show={editedScreen === 'loader'}></LoadingScreenPreview>
      <QRScreenMVARPreview show={editedScreen === 'qr'}></QRScreenMVARPreview>
      <QRScreenWTWPreview show={editedScreen === 'qrWTW'}></QRScreenWTWPreview>
      <GeneralPreview show={editedScreen === 'general'}></GeneralPreview>
    </>
  );
}

export default CustomLoaderPreview;
