export default {
    published: "Published",
    in_revision: "In Review",
    unpublished: "Not Published",
    rejected: "Rejected",
    requiredEmail: 'Email Required',
    requiredPassword: 'Password Required',
    actions: {
        edit: 'Edit',
        copy: 'Copy',
        add: 'Add',
        cancel: 'Cancel',
        accept: 'Accept',
        delete: 'Delete',
        send: 'Send',
        close: 'Close',
        enable: 'Enable',
        disable: 'Disable',
        changeTarget: 'Change Target',
        understood: 'Understood',
        editAR: 'Edit background',
        changeAlias: 'Change alias',
        changeName: 'Change name',
        changeLoading: 'Change Loading',
        converToAd: 'Convert to ad',
        converToNormal: 'undo ad mode'
    },
    common: {
        labels: {
            email: 'Email',
            type: 'Type',
            spanish: 'Spanish',
            english: 'English',
            status: 'Status',
            actions: 'Actions',
            active: 'Active',
            inactive: 'Inactive',
            nameSurname: 'Name and Surname',
            world: 'World',
            plane: 'Flat',
            imageTracking: 'Image Tracking',
            worldTracking: 'World Tracking',
            immersive: 'Web 360',
            360: '360',
            face: 'Face',
            phone: 'Telephone',
            country: 'Country',
            name: 'Name',
            password: 'Password',
            surname: 'Surname',
            company: 'Company',
            project: 'Project',
            user: 'User',
            experience: 'Experience'
        },
        msg: {
            doYouContinue: 'Do you wish to continue?',
            success: 'Success'
        },
        alerts: {
            title: 'Alert'
        },
    },
    header: {
        placeholder: "Search",
        user: 'User',
        profile: 'Profile',
        logout: 'Exit',
        search: 'Start typing to search through your experiences',
        menu: {
            profile: 'Profile',
            subscription: 'Subscription',
            toPro: 'UPGRADE TO PRO',
            logout: 'Logout',
            paymentMethod: 'Payment Method',
            billingInfo: 'Invoice Info',
            billings: 'Invoices',
            companies: 'Companies',
            users: 'Users',
            published: 'Publications',
            sdk: 'SDK'
        }
    },
    sidebar: {
        action: 'New experience',
        menu: {
            campaign: 'Projects',
            recent: 'Recent',
            published: 'Status',
            reports: 'Reports',
            reportsV2: 'Reports',
            webComponents: 'Overlays',
            controlPanel: 'Control panel',
            samplesGallery: 'Demo Gallery',
            videoTutorials: 'Video Tutorials',
        },
        footer: {
            title: 'Your free trial will end in',
            time: 'days',
            action: 'Upgrade to Pro'
        }
    },
    customLoaderSidebar: {
        menu: {
            screenOptions: {
                general: 'General',
                loader: 'Loading Screen',
                qr: 'QR Screen (MVAR)',
                qrWTW: 'QR Screen (World Tracking Web)',
            },
            screens: {
                general: {
                    favicon: "Favicon",
                    documentTitle: "Page Title"
                },
                loader: {
                    header: 'Header image',
                    fonts: 'Fonts',
                    centerLogo: 'Center logo',
                    loadingBar: 'Loading bar',
                    footer: 'Footer text',
                    background: 'Background',
                    grid: 'Grid',
                    gradiant: 'Gradiant',
                    language: 'Language',
                    spanish: 'Spanish',
                    english: 'English',
                    black: 'Black',
                    white: 'White'
                },
                qr: {
                    fonts: 'Fonts',
                    brandImage: 'Brand Image',
                    loadingBar: 'Loading bar',
                    footer: 'Footer text',
                    background: 'Background',
                    grid: 'Grid',
                    gradiant: 'Gradiant',
                    button: 'Button',
                    buttonTextColor: 'Text',
                    buttonBgColor: 'Background',
                    language: 'Language',
                    spanish: 'Spanish',
                    english: 'English',
                    black: 'Black',
                    white: 'White'
                },
                qrWTW: {
                    fonts: 'Fonts',
                    brandImage: 'Brand Image',
                    footer: 'Footer text',
                    background: 'Background',
                    grid: 'Grid',
                    gradiant: 'Gradiant',
                    language: 'Language',
                    spanish: 'Spanish',
                    english: 'English',
                    black: 'Black',
                    white: 'White'
                }
            },
            favicon: "Favicon",
            header: 'Header image',
            loadingHeaderFonts: 'Fonts',
            headerText: 'Header text',
            centerLogo: 'Center logo',
            loadingBar: 'Loading bar',
            footer: 'Footer text',
            background: 'Background',
            grid: 'Grid',
            gradiant: 'Gradiant',
            buttons: 'Buttons',
            language: 'Language',
            spanish: 'Spanish',
            english: 'English',
            black: 'Black',
            white: 'White'
        }
    },

    dragAndDropFile: {
        textBackground: 'Add or drag the selected background',
        text: 'Select an image',
        textHeader: 'Add or drag the selected image',
        format: 'Image formats SVG, PNG or JPEG',
        formatIcon: 'Image formats ICO',
        format2: 'Image formats .jpg or .jpeg',
        quit: 'Delete image',
        add: 'Add image',
        inputSelect: 'Select an option' 
    },

    customLoaderLayout: {
        title: 'Edit custom loader',
        error: 'Error',
        back: 'Back to projects',
        apply: 'Apply changes',
        discard: 'Return to default',
        applyChanges: 'Changes applied successfully',
        errorChanges: 'An error has occurred',
        discardMessage: 'All customization will be deleted. Are you sure?',
        formatImage: 'Only images in svg, png or jpeg format can be uploaded',
        formatImageIcon: 'Only images in ico format can be uploaded',
        yes: "Yes"
    },

    editAr:{
        error: 'Error',
        formatImage: 'Only images in .jpg or .jpeg format can be uploaded',
        ok: "OK"
    },

    experienceNewFlow: {
        invalidSize: 'Target must be 5 MB or less',
        internalError: 'Internal error, try again later',
        title: 'Choose an experience type',
        imageTracking: 'Image Tracking',
        imageTrackingDescription: 'In this type of experience you will be able to create interactive content in augmented reality on a physical image (target), for example: a product label, a poster, a mural, etc.',
        worldTracking: 'World Tracking',
        worldTrackingDescription: 'By creating a “World Tracking” experience you will be able to include 2D and 3D content in real environments, positioning them on flat surfaces in the real world.',
        worldTrackingSubtitle: 'Interactive experiences created through Studio can be viewed in 2 ways: via app (CamOn) or through a web browser.',
        worldTrackingApp: 'App',
        worldTrackingWeb: 'Web',
        worldTrackingAppDescription: 'CamOn is the application that transforms the mobile device into a scanner, allowing you to discover interactive experiences on printed media.',
        worldTrackingWebDescription: 'Interactive experiences are viewed through the web browser of a mobile device, accessed through a URL or by scanning a QR code.',
        markerBased: 'With target',
        markerLess: 'No target',
        markerBasedDescription: 'To access the interactive experience, the user has to scan an image through the CamOn application.',
        markerLessDescription: 'If target is not defined, the user accesses the experience by scanning a QR code with the camera of the mobile device, or through a web link.',
        subtitleWorldTracking: 'Define the way to access these experiences',
        prox: 'Coming soon',
        app: 'App',
        web: 'Web',
        immersiveAppDescription: 'CamOn is the application that transforms the mobile device into a scanner, allowing you to discover interactive experiences on printed media.',
        immersiveWebDescription: 'Interactive experiences are viewed through the web browser of a mobile device, accessed through a URL or by scanning a QR code.',
        immersive: 'Web 360',
        subtitleImmersive: 'Interactive experiences created through Studio can be viewed in 2 ways: via app (CamOn) or through a web browser.',
        subtitleAppImmersive: 'Define the way to access these experiences',
        immersiveDescription: 'The “Web 360” experiences place the user within a 360 ° context, allowing them to discover interactive content around them. ',
        faceTracking: 'Face Tracking',
        new: 'New',
        nameOfExperience: 'Name of the experience',
        nameOfCollection: 'Project name',
        nameOfCampaign: 'Campaign name',
        addTarget: 'Add a target',
        createExperience: 'Create experience',
        offlineExperience: 'Offline Experience',
        offlineTargetType: 'Target Type',
        offlineName: 'Offline Name',
        forAnotherUser: 'For another user',
        advertisement: "Advertisement"
    },
    studioNewFlow: {
        interactiveExperince: "Web 360",
        labelFromDragComponent: "Add models",
        infoImage: 'Drag your 3D model into the box.',
        new: "Nuevo",
        title: " Choose an experience Type",
        interactiveExperinceDescription: "The “Web 360” experiences place the user within a 360 ° context, allowing them to discover interactive content around them.",
        model3d: "Web AR",
        model3dDescription: "When you create a ModelViewer experience, you will be able to generate a 3D object viewer by positioning them on flat surfaces of the real world, proposing virtual testers.",
        addModelGLB: "Add GLB",
        addModelUSDZ: "Add USDZ",
        addOrDragModel: 'Drag your 3D models',
        format: "First, a .GLB format model must be added and then a USDZ format model must be added.",
        changeModel: {
            invalidFile: 'The following files were excluded due to a loading problem: ',
            validFormat: 'Wrong file format',
            validEmptyTarget: 'Must be add a model',
        },
        newExp: {
            formatsModels: '.glb extension only',
            formatModels2: '.usdz extension only'
        },
        uploadsModels: 'Upload models',
        maxFilesError: '1 file is the limit to upload simultaneously. Please try again.',
        nameError: 'There is already an experience with the same name in this company. Please change the name to a different one.'
    },
    experiences: {
        addTarget: 'Add Target',
        nameExp: 'Experience Name',
        infoImage: 'Drag an image from your desktop to watch it.',
        infoExpInput: 'Name of the experience.',
        infoCampaign: 'All experience must be within a project. To create a new project just type its name; To add the experience to an existing project, write the first letters of the project and select it from the suggestions menu.',
        addOrDragTarget: 'Add or drag target',
        newAlias: 'mvar.camonapp.com/?a=',
        newName: 'New Name',
        success: 'Success',
        successBody: 'Changes are made successfully',
        error: 'Error alias already exists',
        editBackground: 'Edit background',
        changeBackground: 'Change background color',
        color: 'Color',
        image: 'Image',
        cloneDemoExpNotice: 'To edit this experience, you need to clone it into your projects. \nDo you want to proceed?',
        actions: {
            seeTarget: 'See Target',
            changeTarget: 'Change Target',
            edit: 'Edit experience name',
            active: 'Activate Experience',
            deactive: 'Deactivate Experience',
            enableVRMode: 'Enable VR mode',
            disableVRMode: 'Disable VR mode',
            cloneExp: 'Clone Experience',
            cloneDemoExp: 'Clone Experience into my Projects',
            migExp: 'Migrate Experience',
            migExpAdmin: 'Migrate Experience to another user',
            removeExp: 'Delete Experience',
            open: 'Open',
            toEdit: 'Edit',
            seeReports: 'View Reports',
            watchQR: 'QR Code',
            changeURL: 'You are about to change the URL of the experience.',
            endDate: 'You are about to schedule the experience URL change for '
        },
        msgActions: {
            changeTarget: 'Updated target',
            clone: 'Cloned experience',
            activate: 'Activated experience',
            deactivate: 'Disabled experience',
            migrate: 'Migrated experience',
            enableVRMode: 'VR Mode Compatibility enabled',
            disableVRMode: 'VR Mode Compatibility disabled',
        },
        vrMode: {
            tooltipPublishRequired: 'You first need to publish the experience to production to make use of this feature',
            requireProSubscription: 'This feature is available only to PRO subscriptors'
        },
        QRDialog: {
            QRDialogTitle: 'QR Code',
            published: 'This experience is published',
            notPublished: 'This experience is not published',
            tooltipMessage: 'Copied!',
            copyToClipboard: 'Copy to clipboard'
        },
        changeTarget: {
            validFormat: 'You have to add an image with valid format (.jpg)',
            validEmptyTarget: 'It is necessary to add a target',
            invalidSize: 'Target must be 5 MB or less',
        },
        cloneExp: {
            addOrDragTarget: 'Add or drag the target'
        },
        deleteExp: {
            msg: 'Are you sure you want to delete the experience?'
        },
        newExp: {
            imgTracking: 'Image Tracking',
            chooseTypeExp: 'Choose an experience type',
            createExp: 'Create experience',
            expTypePlane: 'flat type experiences',
            expTypeImageTracking: 'image tracking experiences',
            createExpPlane: 'Create a flat experience',
            createExpImageTracking: 'Image Tracking',

            expTypeWorldTracking: 'experiences of type world tracking',
            createExpWorldTracking: 'World Tracking',

            expTypeImmersive: '360 Web experiences',
            createExpImmersive: 'Web 360',

            expTypeWorld: 'world type experiences',
            activeExp: 'that will activate your experience',
            formatsTarget: 'Target formats .jpg',
            campaignSelectOrCreate: 'Select or create a Project',
            previewText: {
                title: 'Tips for creating a printed medium with good detection quality:',
                description: 'Variety of shapes and contrasts, Designs with angular shapes that generate well-defined vertices, figures and backgrounds, graphic content on most surfaces of the composition.',
                footer: 'For more information about the targets visit our',
                support: 'Support page'
            },
            valids: {
                requiredName: 'Add a experience name',
                requiredProject: 'Select one proyect',
                requiredTarget: 'Select a target',
                ExperienceAllreadyExists: 'There already exists an experience with that name in this proyect. Please choose a different one'
            }
        }
    },
    pages: {
        signUp: {
            errors: {
                confirmPassReq: 'Confirm password required',
                emailReq: 'Email required',
                passwordReq: 'Password required',
                passwordLength: "The password is not long enough",
                passwordNotMatch: 'Passwords do not match',
                accountExist: 'An account with this email address already exists',
                characters: 'The password must have at least one number and one symbol',
                passwordMayus: 'The password must have some capitalized character',
                passwordMinus: 'The password must have some lowercase character',
                passwordSymbol: 'The password must have some symbol character',
                someValueNull: 'You must fill in all fields',
                currentPasswordInvalid: 'The current password is incorrect.',
                limitReached: 'You have exceeded the Limit of attempts, please try again later.',
                notEmpty:'Fields cannot be empty',
            },
            registerInStudio: 'Register in Studio for free and start creating augmented reality experiences',
            haveAnAccount: 'Do you already have an account?',
            enter: 'Login',
            conditions: 'By creating an account in CamOn Studio I accept the',
            conditionsPayment: 'By purchasing an account in CamOn Studio I accept the',
            conditionsTwo: 'Terms and Conditions',
            conditionsAnd: 'and',
            politicies: 'Privacy policies',
            createFreeAccount: 'Register'
        },
        signIn: {
            errors: {
                userNotFound: 'User not found',
                notAuthorized: 'Incorrect email or password',
                notConfirmed: "User not confirmed",
                userNoGroups: 'Insufficient permissions'

            },
            resetPass: 'To be able to enter CamOn Studio you will need to change your password',
            isNewUser: 'New user?',
            createFreeAccount: 'Create your free account',
            forgotMyPass: 'I forgot my password',
            enterStudio: 'Enter Studio',
            enter: 'Login'
        },
        campaigns: {
            modal: {
                edit: {
                    title: 'Edit project name'
                },
                delete: {
                    title: 'Delete Project',
                    msg: 'Are you sure to delete the project?'
                }
            },
            openProject: 'Open Project',
            experiences: 'Experiences',
            experience: 'Experience',
            namePlaceHolder:'Name',
            actions: {
                changeName: 'Change Name',
                delete: 'Delete',
                editCustomLoader: 'Edit custom loader',
                viewReport: 'View Reports',
                migProject: 'Migrate Project'
            },
            project: 'Projects'
        },
        campaign: {
            tutorial: {
                title: 'Learn to use Studio',
                subtitle: 'Watch our tutorials and learn all the features of CamOn Studio'
            },
            projectBreadcrumb: 'Project'
        },
        profile: {
            deleteAccount: {
                dialog: 'Are you sure you want to carry out this action? For security, fill in the lower field with your email. ',
                inputLabel: 'mail',
            }
        },
        subscription: {
            linkPricing: 'View pricing',
            form: {
                title: 'Send us your information and we will contact you',
                paymentMethod: 'Payment method',
                discountCoupon: 'Discount coupon',
                insertDiscount: 'Enter your discount coupon here',
                insertUsername: 'Enter your name',
                insertSurname: 'Enter your surname',
                insertPhone: 'Enter a valid phone number',
                insertBillingAddress: 'Enter your address',
                insertCity: 'Enter the city or town where you are located',
                insertCountry: 'Enter the country where you are located',
                errorCoupon: 'Coupon does not exist',
                fieldReq: 'Please fill the field',
                emailReq: 'Email required',
                validEmail: "Enter a valid email",
                name: 'Username',
                surname: 'Surname',
                cpCode: 'Postal Code',
                address: 'Address',
                city: 'City',
                country: 'Country',
                validTelephone: 'This field only accept number values',
                reqTelephone: 'Please fill the phone field',
                insertCP: 'ZIP Code',
                promotions: 'Promotions',
                billing: 'Billing',
                personalData: 'Personal Data',
                labels: {
                    coupon: 'Coupon',
                    username: 'Name',
                    surname: 'Surname',
                    phone: 'Telephone',
                    spanish: 'Spanish',
                    english: 'English',
                    address: 'Address',
                    postalCode: 'Postal Code',
                    city: 'City / Town',
                    country: 'Country',
                    submit: 'Send'
                },
                success: 'We will contact you shortly.'
            },
            month: 'month',
            year: 'year',
            title: 'Subscription',
            basicPlan: 'Basic Account',
            professionalPlan: 'Professional Account',
            canCancelSubscription: 'You can cancel the subscription whenever you want',
            annualSaving: 'You save 240 USD',
            upgradePlan: 'Upgrade to Pro',
            yearlyPlan: 'Annual Plan',
            monthlyPlan: 'Monthly Plan  ',
            withoutCreditCard: 'Access without credit card',
            unlimitedExperiences: 'Create unlimited experiences',
            publishDrafts: 'Publish drafts',
            views: 'views',
            monthlyScan: 'monthly scans',
            accessReportsMetrics: 'Access to reports and metrics',
            helpCenter: 'Help Center',
            personalSupport: 'Personalized support'
        },
        onboarding: {
            welcome: 'Welcome to Studio',
            firstExperience: 'Create your first experience in CamOn Studio to know all the functionalities and options of this tool',
            buttonFirst: 'Create your first experience'
        },
        confirm: {
            goToLogin: 'Go to login',
            confirmationSend: {
                mailConfirmSent: 'A confirmation email has been sent',
                checkMail: 'Check your email and confirm your account',
            },
            resetSend: {
                mailSent: 'We have sent you an email',
                checkMail: 'Check your email and follow the instructions to reset your password'
            },
            resetOk: {
                passReset: 'You have successfully reset your password'
            }
        },
        endOfTrial: {
            endTrial: 'Your trial period ended',
            paragraph: 'We hope you have enjoyed using Studio so far, if you want to continue using it from now on upgrade your account to Pro. Remember that you can unsubscribe at any time you want and that your projects will be there for you when you decide to update your bill',
            upgrade: 'Upgrade to PRO'
        },
        error: {
            404: 'We could not find the page you are looking for',
            return: 'Go back to your recent experiences'
        },
        warningMobile: "CamOn Studio is currently available only on the desktop version.",
        account: {
            tabs: {
                subscription: 'Subscription',
                companies: 'Companies',
                users: 'Users',
                publishes: 'Publications',
                educationals: 'Educational Entities',
                professors: 'Professors',
                students: 'Students',
                profile: 'Profile'
            },
            deleteAccount: 'Validation failed',
            backToProject: 'Back to Projects',
            sureMsg: 'Are you sure?',
            pagination: {
                prev: 'Previous',
                next: 'Next'
            },
            addMsg: 'Created successfully',
            classroom: {
                modal: {
                    add: {
                        title: 'Add Chair'
                    },
                    edit: {
                        title: 'Edit Chair'
                    }
                },
                headTable: {
                    name: 'Name',
                    code: 'Code',
                    professor: 'Professor',
                    emailProfessor: 'Email Teacher',
                    actions: 'Actions'
                },
                pagination: {
                    prev: 'Previous',
                    next: 'Next'
                },
                form: {
                    nameClassroom: 'Chair Name',
                    nameProfessor: 'Teacher Name',
                    surnameProfessor: 'Last name Professor',
                    emailProfessor: 'Email Professor'
                }
            },
            companies: {
                modal: {
                    add: {
                        title: 'Add Company'
                    },
                    edit: {
                        title: 'Edit Company'
                    },
                    delete: {
                        title: 'Delete Company',
                        msg: 'Are you sure you want to delete the company?'
                    }
                },
                headTable: {
                    name: 'Name',
                    type: 'Type',
                    canPublish: 'Can Publish',
                    reports: 'You can View Reports',
                    actions: 'Actions'
                },
                pagination: {
                    prev: 'Previous',
                    next: 'Next'
                },
                form: {
                    credentials: 'Credentials'
                }
            },
            educational: {
                addEntity: 'Add Entity',
                modal: {
                    add: {
                        title: 'Add Educational Entity'
                    },
                    edit: {
                        title: 'Edit Educational Entity'
                    },
                    delete: {
                        title: 'Delete Educational Entity',
                        msg: 'Are you sure you want to delete the educational institution?'
                    }
                },
                headTable: {
                    name: 'Name',
                    type: 'Type',
                    canPublish: 'Can Publish',
                    reports: 'View Reports',
                    actions: 'Actions'
                },
                pagination: {
                    prev: 'Previous',
                    next: 'Next'
                },
                form: {
                    entityEducational: 'Educational Entity',

                }
            },
            professor: {
                modal: {
                    add: {
                        title: 'Add Teacher'
                    },
                    edit: {
                        title: 'Edit Teacher'
                    },
                    delete: {
                        title: 'Delete Teacher',
                        msg: 'This action will remove all related content.',
                        continue: 'Do you want to continue?'
                    },
                }
            },
            publishes: {
                notification: {
                    rejected: 'Rejected',
                    msgRejected: 'Successfully rejected',
                    approved: 'Approved',
                    msgApproved: 'Passed successfully'
                },
                modal: {
                    approve: {
                        title: 'Approve Publication',
                        msg: 'Post will be approved'
                    },
                    rejected: {
                        title: 'Reject Publication',
                        msg: 'Post will be rejected.'
                    }
                },
                headTable: {
                    campaign: 'Campaign',
                    name: 'Name',
                    out: 'Exit',
                    date: 'Date',
                },
                actions: {
                    see: 'See',
                    approve: 'Approve',
                    rejected: 'Reject'
                }
            },
            student: {
                modal: {
                    add: {
                        title: 'Add Student',
                    },
                    edit: {
                        title: 'Edit Student',
                    },
                    delete: {
                        title: 'Delete Student',
                        msg: 'This action will remove all related content.'
                    }
                },
                headTable: {
                    classroom: 'Chair',
                },
            },
            subscription: {
                yearlyPlan: 'Annual Plan',
                monthlyPlan: 'Monthly Plan',
                modal: {
                    cancel: {
                        title: 'Do you want to cancel the Subscription?'
                    }
                },
                labels: {
                    free: 'Free',
                    expiration: 'Your free trial ends in',
                    expired: 'Your free trial has ended',
                    days: 'days',
                    freePlan: 'You are currently on a free plan',
                    noPurchase: 'You haven\'t made any purchases yet ',
                    yourPlan: 'Your plan',
                    profesionalSubscription: 'Professional Subscription',
                    untilValid: 'Valid until',
                    planAnnual: 'Annual Subscription',
                    planMonthly: 'Monthly Subscription',
                    cancelButton: 'Cancel Subscription',
                    paymentMethod: 'Payment method',
                    creditCard: 'Credit card',
                    billingInfo: 'Billing information',
                    nameSurname: 'Name and Surname',
                    address: 'Address',
                    city: 'City',
                    country: 'Country',
                    invoices: 'Invoices',
                    downloadInvoice: 'Download Invoice',
                    subscriptionCancelled: 'Subscription was successfully canceled',
                    subscription: 'Annual'
                }
            },
            userProfile: {
                modal: {
                    edit: {
                        title: 'Edit account information'
                    },
                    sec: {
                        title: 'Edit security information'
                    },
                    delete: {
                        title: 'Delete account'
                    },
                    changePass: {
                        title: 'Alert',
                        msg: 'The session will be closed due to the password change'
                    }
                },
                labels: {
                    fakeName: 'Name',
                    user: 'USER',
                    phone: 'Telephone',
                    language: 'Language',
                    security: 'Security',
                    password: 'Password',
                    inviteLink: 'Invitation link',
                    link: 'Link',
                    deleteAccount: 'Delete account',
                    deleteAccountStudio: 'Delete my CamOn Studio account',
                    phoneInvalid: 'Invalid phone number',
                    previousPassword: 'Previous password',
                    newPassword: 'New password',
                    confirmPassword: 'Confirm password',
                    spanish: 'Spanish',
                    english: 'English'
                }
            },
            users: {
                modal: {
                    add: {
                        msg: 'Created successfully',
                        title: 'Add User'
                    },
                    update: {
                        msg: 'Updated successfully',
                        title: 'Edit User'
                    },
                    disable: {
                        msg: 'This action will unpublish all related content.',
                        title: 'Disable User'
                    },
                    delete: {
                        title: 'Delete User',
                        msg: 'This action will remove all related content.'
                    },
                    edit: {
                        title: 'Edit User'
                    }
                },
                form: {
                    labelUserType: 'User type',
                    userType: {
                        userCamonapp: 'CamOn Editor',
                        marketing: 'Marketing',
                        admin: 'Administrator',
                        companyUser: 'Company User'
                    },
                    company: 'Company'
                }
            }
        },
        experiencesT: 'Experiencias',
        recent: {
            recentExp: 'Recent Experiences'
        },
        samplesGallery: {
            samplesGallery: 'Sample Experiences'
        },
        videoTutorials: {
            project: 'Videos'
        },
        reports: {
            reports: 'Reports',
            tabs: {
                allCompanies: 'All companies',
                campaign: 'CAMPAIGN',
                experience: 'EXPERIENCE'
            },
            alertTitle: 'Generating Report',
            alertBody: 'The report is being generated. You will receive an e-mail in a few minutes',
            headTable: {
                company: 'Company',
                filter: 'Filter',
                views: 'Views',
                campaign: 'Campaign',
                experience: 'Experience',
                actionType: 'Type of Interaction',
                quantity: 'Quantity'
            },
            totalViews: 'Total Views',
            users: 'Users',
            time: 'Average time',
            downloadReport: 'Download Report',
            viewForDay: 'Number of views per day',
            statistics: 'Statistics',
            cantView: 'Number of views - interaction dates',
            demographicData: 'Demographic data',
            so: 'Operating System'
        },
        webComponents: {
            webComponents: "Overlays"
        },
        customForgot: {
            paragraph: 'Enter your email with which you registered and we will send you the instructions to recover the password',
            recoveryPass: 'Recover your password',
            resetPass: 'Reset your password',
            backToLoginButton: 'Ok, login again',
            infoMsg: 'Check your account, you will receive an email with instructions on how to reset your password. Remember to check your spam folder.',
            titleMsg: 'We have sent you an email'
        },
        requireNewPass: {
            passwordReset: 'Password reset',
            updatePassword: 'Update password',
            titleMsg: 'You have successfully reset your password'
        },
        status: {
            statusExp: 'Status of your experiences',
            seeAll: 'See all'
        }
    },
    notification: {
        action: {
            expCreated: "Experience created with Success"
        },
        label: "Close"
    },
    filters: {
        labels: {
            status: 'Status',
            experienceType: 'Type of experience',
            order: 'Sort by',
        },
        status: {
            all: 'All',
            published: 'Published',
            notPublished: 'Not published',
            onReview: 'Review',
            rejected: 'Rejected'
        },
        expType: {
            all: 'All',
            world: 'World',
            plane: 'Flat'
        },
        sort: {
            recent: 'Recent',
            created: 'Creation date',
            name: 'Name'
        },
        project: 'Projects'
    },
    help: {
        goToSupport: 'Go to support',
        termsAndConditions: 'Terms and conditions',
        privacyPolicies: 'Privacy policies'
    },
    assets: {
        error: {
          maxVertices: "Exceeds the maximum number of polygons allowed: 75000 polygons",
          maxResolutionGlb: "Exceeds the maximum resolution allowed for textures: 2048x2048"
        }
    },
    overlays:{
        title: "Overlays",
        selectComponenLabel: "Overlays",
        visualiser:{
            desktop: "Desktop",
            mobile: "Mobile"
        },
        propertyLabel: "Property",
        properties:{
            select: "Variables",
            visibility: "Visibility",
            height:"Height",
            width: "Width",
            size: "Size",
            personalizationButton: "Personalization button",
            background:"Background",
            iconUrl:"Image",
            setUrl: "Url image",
            primaryTextColor: "Primary text color",
            secondaryTextColor: "Secondary text color",
            primaryBackgroundColor: "background color",
            secondaryBackgroundColor: "Secondary background color",
            primaryBackgroundUrl: "Rest image url",
            secondaryBackgroundUrl: "On click image url",
            terciaryBackgroundUrl:"Image url on click",
            shape:"Shape",
            type:"Type",
            top: "Top margin distance",
            left: "Left margin distance",
            right: "Right margin distance",
            bottom: "Bottom margin distance",
            unity: "Unity",
            url: "Url",
            steps:"Steps",
            title:"Title",
            description:"Description",
            activeItemColor:"Stepper color active",
            itemColor: "Stepper color pasive",
            buttonColor:"Button color",
            font:"Font",
            timeToAppear: "Delay",
            textLeftButton: "Left click text",
            textRightButton: "Right click text", 
            position:"Position",
            header:"Tittle",
            transitionDirection:"Transition",
            transitionLTR: "Left to rigth",
            transitionRTL: "Rigth to left",
            leftPosition: "Left",
            rightPosition: "Right",
            centerPosition:"Center",
            new: "New",
            current: "Current",
            tab: "Tab",
            items:"Item to menu",
            href:"Destination url",
            textAlign: "Text align",
            text:"Text",
            textAndColor: "buttons text and color",
            buttonsColor: "Buttons color",
            alignment: "Alignment",
            primaryText: "Primary text",
            secondaryText: "Secondary text",
            textColor: "Text color",
            textColorButtons: "Buttons text color",
            backgroundRightButton: "Right button color",
            backgroundLefttButton: "Left button color",
            backgroundRightButtonHover: "Right button color hover",
            backgroundLefttButtonHover: "Left button color hover",
            backgroundRightButtonClick: "Right button color click",
            backgroundLefttButtonClick: "Left button color click",
            textColorLeftButtonHover: "Left button text color on hover",
            textColorRightButtonHover: "Right button text color on hover",
            textColorLeftButton:"Text color left button",
            textColorRightButton:"Text color right button",
            colors: "Colors",
            closeButton: "Close Button",
            mesaures: "Measures"
        }
    },
        export:  "Export",
        includeLibrary: "Include library",
        copied:"Copied",
        copy: "Copy",
        component : {
            cowcFooterBotton: {
                value: "cowc-footer-botton",
                label: "Footer"
            },
            cowc360NavigationTour:{
                value: "cowc-360-navigation-tour",
                label: "Navigation tour"
            },
            cowcBackButton:{
                value: "cowc-back-button",
                label: "Back button"
            },
            cowcIconButton: {
                value: "cowc-icon-button",
                label: "Icon button"
            },
            cowcMenu:{
                value: "cowc-menu",
                label: "Menu"
            },
            cowcModal:{
                value: "cowc-modal",
                label: "Modal"
            },
            cowcModalWithTrigger:{
                value: "cowc-modal-with-trigger",
                label: "Modal with button"
            },
            cowcShopButton:{
                value: "cowc-shop-button",
                label: "Shop button"
            }
    },
    tablePublications: {
        title: 'Control panel',
        experience: 'Experience',
        user: 'User',
        limitDate: 'End Date',
        timer: 'Timer',
        status: 'Off / On'
    },
    dateModal: {
        msjError: "Please select a future date."
    },
}