import React, {useState, useContext} from 'react';
import {useTranslate} from 'react-polyglot';
import '@rmwc/badge/badge.css';
import '../CustomLoaderMenu.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InputFileDragAndDrop from '../../inputFileDragAndDrop/InputFileDragAndDrop'
import '@material/textfield/dist/mdc.textfield.css';
import { CustomLoaderContext } from '../../../context/CustomLoaderContext';
import {
    FontSizeOutlined,
    PictureOutlined
  } from '@ant-design/icons';


function General(props) {

    const t = useTranslate();
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })
    const {
            generalSettings, updateGeneralSettings,
            DEFAULT_FAVICON,
            setChangesSaved,
        } = useContext(CustomLoaderContext);


    const [faviconSettingsOpen, setFaviconSettingsOpen] = useState(false);
    const [documentTitleSettingsOpen, setDocumentTitleSettingsOpen] = useState(false);

    const closeAll = () => {
        setFaviconSettingsOpen(false);
        setDocumentTitleSettingsOpen(false)
    }
    
    const handleMenuClick = (currentState, openSetter) => {
        closeAll();
        openSetter(!currentState)
    }

    const handleUpdateSettings = (newSettings) => {
        setChangesSaved(false);
        updateGeneralSettings(newSettings)
    }

    return (
        <List>
            <ListItem button onClick={() => handleMenuClick(faviconSettingsOpen, setFaviconSettingsOpen)} className={faviconSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <PictureOutlined src={`/images/reports-hovered.svg`} style={{
                                            marginLeft: '8px',
                                            marginRight: '8px',
                                            width: '16px',
                                            height: '16px'
                                        }}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.general.favicon')} />
            </ListItem>
            <Collapse in={faviconSettingsOpen} unmountOnExit>
                <ListItem>
                    <InputFileDragAndDrop
                        id="favicon"
                        acceptedExtensions={".ico"}
                        warningTextInvalidFormat={t('customLoaderLayout.formatImage')}
                        infoTextValidFormats={t('dragAndDropFile.formatIcon')}
                        setOpen={setOpen}
                        handleURL={(url) => handleUpdateSettings({favicon: {url}})}
                        handleFile={(file) => handleUpdateSettings({favicon: {file}})}
                        defaultFile={generalSettings.favicon.local}
                        onFileLoaded={(filePath) => {
                            handleUpdateSettings({
                                favicon: {
                                    local: filePath,
                                    reset: false,
                                }
                            })
                        }}
                        onQuitFile={() => {
                            handleUpdateSettings({
                                favicon: {
                                    url: DEFAULT_FAVICON,
                                    local: undefined,
                                    file: undefined,
                                    reset: true
                                }
                            })
                        }}
                    ></InputFileDragAndDrop>
                </ListItem>
            </Collapse>
            <ListItem button onClick={() => handleMenuClick(documentTitleSettingsOpen, setDocumentTitleSettingsOpen)} className={documentTitleSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <FontSizeOutlined style={{marginLeft: '8px', marginRight: '8px', width: '16px', height: '16px'}} />
                    <ListItemText primary={t('customLoaderSidebar.menu.screens.general.documentTitle')} />
                </ListItem>
                <Collapse in={documentTitleSettingsOpen}>
                    <ListItem className="text-field">
                        <input
                            value={generalSettings.documentTitle}
                            onChange={(e) => handleUpdateSettings({documentTitle: e.target.value})}
                            className="text-field-text"
                        />
                    </ListItem>
                </Collapse>
        </List>
    );
}

export default General;
