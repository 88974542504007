import "@rmwc/badge/badge.css";
import React, { useContext, useRef } from "react";
import { CustomLoaderContext } from "../../../context/CustomLoaderContext";
import { getFontFamily } from "../../CustomLoaderMenu/availableFonts";

import "../CustomLoaderPreview.css";
import Icon360SVG from "../Icon360SVG";


function LoadingScreenPreview(props) {
  const {
    loadingScreenSettings,
    language,
  } = useContext(CustomLoaderContext);


  const logoHeaderRef = useRef()
  const logoHeaderMeasuresRef = useRef()
  const logoCenterRef = useRef()
  const logoCenterMeasuresRef = useRef()
  const logoFooterRef = useRef()
  const logoFooterMeasuresRef = useRef()

  const hasCenterLogo = (loadingScreenSettings.centerImage.local !== undefined) && (loadingScreenSettings.centerImage.local !== '');

  const styles = {
    textColor: {
      color: loadingScreenSettings.textColor,
    },
    headerText: {
      fontFamily: getFontFamily(loadingScreenSettings.headerFont),
      lineHeight: '0'
    },
    notDisplay: {
      display: 'none'
    },
    centerLogoHeight: {
      height: '155px',
    },
    loadingContainerPosition: {
      transform: "translate(-50%, calc(-50% - 50px))"
    },
    loadingBarColor: {
      color: loadingScreenSettings.loadingBarColor,
      borderColor: loadingScreenSettings.loadingBarColor,
      backgroundColor: loadingScreenSettings.loadingBarColor,
    },
    backgroundColor: {
      color: loadingScreenSettings.background.color,
      background: loadingScreenSettings.background.isGradiant 
        ? `linear-gradient(${loadingScreenSettings.background.color}, ${loadingScreenSettings.background.gradiantColor})`
        : loadingScreenSettings.background.local?.length
        ? "0"
        : loadingScreenSettings.background.color,
      backgroundColor: loadingScreenSettings.background.local?.length ? "0" : loadingScreenSettings.background.color,
    },
    centerLogoFile: {
      backgroundImage: `url(${loadingScreenSettings.centerImage.local !== 'undefined' ? loadingScreenSettings.centerImage.local : loadingScreenSettings.centerImage.file})`,
    },
    backgroundImageGrid: {
      backgroundImage: "url('https://cdn-studio.camonapp.com/_loader/brand/base/img/loader_bg.svg')",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      opacity: 0.3,
      height: '100%',
      width: '100%',
      top: '163px'
    },
    backgroundFile: {
      backgroundImage: `url(${loadingScreenSettings.background.local !== 'undefined' ? loadingScreenSettings.background.local : loadingScreenSettings.background.file})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    logoImage: {
      backgroundImage: loadingScreenSettings.headerImage.url
        ? `url(${loadingScreenSettings.headerImage.url}) no-repeat center`
        : "url(/images/icon_360.svg) no-repeat center",
    },
    iconColor: {
      color: loadingScreenSettings.iconColor,
    },
    iconSVG: {
      width: "50px",
      margin: "40px auto 10px auto"
    },
    footerImage: {
      maxHeight: '50px'
    }
  };

  const onMouseOverArea = (e) => {
    if (logoHeaderMeasuresRef.current) {
      logoHeaderMeasuresRef.current.style.display = 'block'
    }
    if (logoHeaderRef.current) {
      logoHeaderRef.current.style.outline = '2px yellow dashed'
    }
    if (logoCenterRef.current) {
      logoCenterRef.current.style.outline = '2px yellow dashed'
      logoCenterMeasuresRef.current.style.display = 'block'
    }

    if (logoFooterRef.current) {
      logoFooterRef.current.style.outline = '2px yellow dashed'
      logoFooterMeasuresRef.current.style.display = 'block'
    }
  }
  const onMouseOutArea = (e) => {
    if (logoHeaderMeasuresRef.current) {
      logoHeaderMeasuresRef.current.style.display = 'none'
    }
    if (logoHeaderRef.current) {
      logoHeaderRef.current.style.outline = ''
    }
    if (logoCenterRef.current) {
      logoCenterRef.current.style.outline = ''
      logoCenterMeasuresRef.current.style.display = 'none'
    }
    if (logoFooterRef.current) {
      logoFooterRef.current.style.outline = ''
      logoFooterMeasuresRef.current.style.display = 'none'
    }
  }

  return (
  <> 
  { props.show &&
    <div id="container" onMouseOver={onMouseOverArea} onMouseOut={onMouseOutArea}>
      <div className="preload-menu">
        <div className="background" style={styles.backgroundFile}>
          <div id="panorama">
            {/* TODO */}
            {/* <div id="gl" data-imageOriginal="{preload_image_original}" data-imageDepth="{preload_image_depth}" data-horizontalThreshold="{preload_ht}" data-verticalThreshold="{preload_vt}"></div> */}
          </div>
        </div>
        <div className="start-experience">
          {/*                 <div className="init-button-container"> 
                    <div id="start-360" className="start-360">
                        <div className="start-360-logo"></div>
                         TODO  
                        <span>txt_start_360</span>
                    </div>
                    <div className="init-loading-indicator">
                        <div className="init-loading-indicator-inner"></div>
                    </div>
                </div> */}
        </div>
      </div>
      <div id="preloader" className="preloader" style={styles.backgroundColor}>
        <div className="preloader-header" style={styles.textColor}>
          {/* <div style={styles.logoImage}></div> */}
          <span ref={logoHeaderMeasuresRef} className="loader-preview-info header-logo-info" style={{display: 'none'}}>50px x 50px</span>
          {loadingScreenSettings.headerImage.url && <img ref={logoHeaderRef} alt="logo" src={loadingScreenSettings.headerImage.url}/> }
          <Icon360SVG hidden={loadingScreenSettings.headerImage.url} style={styles.iconSVG} iconcolor={styles.iconColor.color}></Icon360SVG>
          <br />
          {/* <img src="{header_icon}" /> */}
          {/* TODO  */}
          {
          language === "es"
          ?
            <span style={styles.headerText}>
            Estas ingresando a<br />
            <span className="bold">contenidos 360</span>
            </span>
          :
            <span style={styles.headerText}>
            You are entering<br />
            <span className="bold">360 content</span>
            </span>
          }
        </div>
        <div className="preloader-header-qr">
          {/* TODO  */}
          logo_3d
        </div>
        {
          loadingScreenSettings.background.isGrid &&
          <div className="previewGrid" style={styles.backgroundImageGrid}></div>
        }
        <div className="preloader-center">
          <div className="preloader-bg"/>
          <div className="preloader-container">
            <div className="preloader-qr">
              <div className="preloader-qr-code"></div>
              {/* TODO  */}
              <div className="qr-description-1">txt_no_ar_1_available</div>
              {/* TODO  */}
              <div className="qr-description-2">txt_no_ar_2_available</div>
              <div className="link-p">
                {/* TODO  */}
                <span>txt_ar_back_to_1</span>
                {/* TODO  */}
                <a id="backLink">txt_ar_back_to_2</a>
              </div>
            </div>
            <div className="loading loading-ar">
              {/* TODO  */}
              <div id="start-ar" className="start-360">
                <div className="start-360-logo"></div>
                <span>txt_start_ar</span>
              </div>
            </div>
            <div className="loading loading-bar" style={hasCenterLogo ? styles.loadingContainerPosition : undefined}>
              <div className="loading-content-preview" style={hasCenterLogo ? styles.centerLogoHeight : undefined}>
                <span className="bold" style={hasCenterLogo ? styles.notDisplay : styles.textColor}>
                  360
                </span>
                { hasCenterLogo &&
                  <div ref={logoCenterRef} className="logo-3d" style={styles.centerLogoFile}>
                  <span ref={logoCenterMeasuresRef} className="loader-preview-info central-logo-info" style={{display: 'none'}}>500px (max) x 155px</span>
                  </div>
                }
              </div>
              <div className="loading-indicator" style={styles.loadingBarColor}>
                <div
                  className="loading-indicator-inner"
                  style={styles.loadingBarColor}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer {footer_360}" style={styles.footerColor}>
          <div className="logo">
            <span ref={logoFooterMeasuresRef} className="loader-preview-info footer-logo-info" style={{display: 'none'}}>193px (max) x 71px (max)</span>
            <img ref={logoFooterRef} className="" alt="" style={styles.footerImage} src={
              loadingScreenSettings.footerImage.local
              ? loadingScreenSettings.footerImage.local
              : `https://cdn-studio.camonapp.com/_loader/projects/common/img/footer_360-${loadingScreenSettings.footerThemeColor}.svg`
            }/>
          </div>
        </div>
        {/* TODO  */}
        {/* <div className="footer {footer_ar}"> */}
        {/* TODO  */}
        {/* <img className="poweredby_ar" src="{brand_image_url}footer_poweredby.svg" />            </div> */}
      </div>
      <div className="main-overlay">
        {/* <div className="sound no-select">${
            window.hideMusic
            ? ""
            : `<img src="{base_image_url}icon_sound_off.svg" />`
        }</div> */}

        {/* TODO  */}
        {/* <div className="fullscreen no-select"><img src="{base_image_url}icon_fullscreen.png" /></div> */}
        {/* TODO  */}
        {/* <div className="close no-select"><img src="{base_image_url}icon_close.png" /></div> */}
      </div>
      <div className="ar-overlay">{/* TODO  */}</div>
      <div id="experience-container"></div>
      <div id="debug-console"></div>
    </div>
    }
  </>
  );
}

export default LoadingScreenPreview;
