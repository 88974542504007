import React, {useState, useContext, useReducer} from 'react';
import {useTranslate} from 'react-polyglot';
import '@rmwc/badge/badge.css';
import './CustomLoaderSaveChanges.css';
import '@material/textfield/dist/mdc.textfield.css';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import AlertDialog from '../general/AlertDialog';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import CustomLoaderService from '../../services/CustomLoader';
import Notification from '../notification/notification';

function CustomLoaderSaveChanges(props) {
    const campaignId = window.location.pathname.split('/')[3];
    const t = useTranslate();
    const { setShowLoading } = useContext(ShowLoadingContext)

    const {
        setData,
        generalSettings, loadingScreenSettings, qrMvarScreenSettings, qrWTWScreenSettings,
        DEFAULT_FAVICON,
        updateGeneralSettings,
        updateLoadingSettings,
        updateQRMvarSettings,
        updateQRWTWSettings,
        changesSaved, setChangesSaved,
    } = useContext(CustomLoaderContext);


    const [{ showNotification, propsNotification }, setNotification] = useReducer(
        (prevState, currState) => 
        ({ ...prevState, ...currState }),
        { showNotification: false, 
        propsNotification: {} 
    });
    const [open,setOpen] = useState({
        title : "",
        subtitle: "",
        isOpen: false
    })

    const save = async() => {
        setShowLoading(true)
        let formData = new FormData();
        formData.append("isUpdate", true);
        formData.append("iconColor", loadingScreenSettings.headerImage.default === true ? loadingScreenSettings.iconColor : "null"); //deprecate
        formData.append("textColor", loadingScreenSettings.textColor);     //deprecate
        formData.append("headerFont", loadingScreenSettings.headerFont); //deprecate
        formData.append("loadingBarColor", loadingScreenSettings.loadingBarColor); //deprecate
        formData.append("backgroundColor", loadingScreenSettings.background.color); //deprecate
        formData.append("footerColor", '#FFFFFF'); //deprecate
        formData.append("isGrid", loadingScreenSettings.background.isGrid); //deprecate
        formData.append("isGradiant", loadingScreenSettings.background.isGradiant); //deprecate
        formData.append("backgroundGradiantColor", loadingScreenSettings.background.gradiantColor); //deprecate
        // DEPRECATE>>>>
        formData.append("footerText", '');
        formData.append("footerTextBold", '');
        formData.append("footerTextEn", '');
        formData.append("footerTextBoldEn", '');
        // <<<<DEPRECATE

        // deprecate after removing it from POST to lambda-cletus
        formData.append("footerThemeColor", loadingScreenSettings.footerThemeColor);
        formData.append("logoFile", loadingScreenSettings.headerImage.file);
        formData.append("backgroundFile", loadingScreenSettings.background.file);
        formData.append("resetBackground", loadingScreenSettings.background.reset);
        formData.append("centerLogoFile", loadingScreenSettings.centerImage.file);
        formData.append("resetCenterLogo", loadingScreenSettings.centerImage.reset);
        formData.append("hasCenterLogoURL", loadingScreenSettings.centerImage.local !== '' && loadingScreenSettings.centerImage.local !== undefined);
        formData.append("hasBackgroundURL", loadingScreenSettings.background.local !== '' && loadingScreenSettings.background.local !== undefined);
        formData.append("hasLogoURL", loadingScreenSettings.headerImage.file !== '' && loadingScreenSettings.headerImage.file !== undefined);
        formData.append("hasDefaultLogo", loadingScreenSettings.headerImage.default);
        formData.append("qrButtonTextColor", qrMvarScreenSettings.button.textColor);
        formData.append("qrButtonBgColor", qrMvarScreenSettings.button.backgroundColor); 

        // new data
        formData.append("general.documentTitle", generalSettings.documentTitle);
        formData.append("general.favicon.file", generalSettings.favicon.file);
        formData.append("general.favicon.reset", generalSettings.favicon.reset);
        formData.append("loader.iconColor", loadingScreenSettings.headerImage.default ? loadingScreenSettings.iconColor : "null");
        formData.append("loader.textColor", loadingScreenSettings.textColor);  
        formData.append("loader.headerFont", loadingScreenSettings.headerFont);
        formData.append("loader.headerImage.file", loadingScreenSettings.headerImage.file);
        formData.append("loader.headerImage.reset", loadingScreenSettings.headerImage.reset);
        formData.append("loader.headerImage.enabled", loadingScreenSettings.headerImage.local !== '' && loadingScreenSettings.headerImage.local !== undefined);
        formData.append("loader.loadingBarColor", loadingScreenSettings.loadingBarColor);
        formData.append("loader.background.color", loadingScreenSettings.background.color);
        formData.append("loader.background.isGrid", loadingScreenSettings.background.isGrid);
        formData.append("loader.background.isGradiant", loadingScreenSettings.background.isGradiant);
        formData.append("loader.background.gradiantColor", loadingScreenSettings.background.gradiantColor);
        formData.append("loader.background.file", loadingScreenSettings.background.file);
        formData.append("loader.background.reset", loadingScreenSettings.background.reset);
        formData.append("loader.background.enabled", loadingScreenSettings.background.local !== '' && loadingScreenSettings.background.local !== undefined);
        formData.append("loader.centerImage.file", loadingScreenSettings.centerImage.file);
        formData.append("loader.centerImage.reset", loadingScreenSettings.centerImage.reset);
        formData.append("loader.centerImage.enabled", loadingScreenSettings.centerImage.local !== '' && loadingScreenSettings.centerImage.local !== undefined);
        formData.append("loader.footerThemeColor", loadingScreenSettings.footerThemeColor);
        formData.append("loader.footerImage.file", loadingScreenSettings.footerImage.file);
        formData.append("loader.footerImage.reset", loadingScreenSettings.footerImage.reset);
        formData.append("loader.footerImage.enabled", loadingScreenSettings.footerImage.local !== '' && loadingScreenSettings.footerImage.local !== undefined);
        
        formData.append("qrMvar.fonts.textColor", qrMvarScreenSettings.fonts.textColor);
        formData.append("qrMvar.fonts.family", qrMvarScreenSettings.fonts.family);
        formData.append("qrMvar.background.color", qrMvarScreenSettings.background.color);
        formData.append("qrMvar.background.isGrid", qrMvarScreenSettings.background.isGrid);
        formData.append("qrMvar.background.isGradiant", qrMvarScreenSettings.background.isGradiant);
        formData.append("qrMvar.background.gradiantColor", qrMvarScreenSettings.background.gradiantColor);
        formData.append("qrMvar.background.file", qrMvarScreenSettings.background.file);
        formData.append("qrMvar.background.reset", qrMvarScreenSettings.background.reset);
        formData.append("qrMvar.background.enabled", qrMvarScreenSettings.background.local !== '' && qrMvarScreenSettings.background.local !== undefined);
        formData.append("qrMvar.brandImage.file", qrMvarScreenSettings.brandImage.file);
        formData.append("qrMvar.brandImage.reset", qrMvarScreenSettings.brandImage.reset);
        formData.append("qrMvar.brandImage.enabled", qrMvarScreenSettings.brandImage.local !== '' && qrMvarScreenSettings.brandImage.local !== undefined);
        formData.append("qrMvar.footerThemeColor", qrMvarScreenSettings.footerThemeColor);
        formData.append("qrMvar.footerImage.file", qrMvarScreenSettings.footerImage.file);
        formData.append("qrMvar.footerImage.reset", qrMvarScreenSettings.footerImage.reset);
        formData.append("qrMvar.footerImage.enabled", qrMvarScreenSettings.footerImage.local !== '' && qrMvarScreenSettings.footerImage.local !== undefined);
        formData.append("qrMvar.button.textColor", qrMvarScreenSettings.button.textColor);
        formData.append("qrMvar.button.backgroundColor", qrMvarScreenSettings.button.backgroundColor);

        formData.append("qrWTW.fonts.textColor", qrWTWScreenSettings.fonts.textColor);
        formData.append("qrWTW.fonts.family", qrWTWScreenSettings.fonts.family);
        formData.append("qrWTW.background.color", qrWTWScreenSettings.background.color);
        formData.append("qrWTW.background.isGrid", qrWTWScreenSettings.background.isGrid);
        formData.append("qrWTW.background.isGradiant", qrWTWScreenSettings.background.isGradiant);
        formData.append("qrWTW.background.gradiantColor", qrWTWScreenSettings.background.gradiantColor);
        formData.append("qrWTW.background.file", qrWTWScreenSettings.background.file);
        formData.append("qrWTW.background.reset", qrWTWScreenSettings.background.reset);
        formData.append("qrWTW.background.enabled", qrWTWScreenSettings.background.local !== '' && qrWTWScreenSettings.background.local !== undefined);
        formData.append("qrWTW.brandImage.file", qrWTWScreenSettings.brandImage.file);
        formData.append("qrWTW.brandImage.reset", qrWTWScreenSettings.brandImage.reset);
        formData.append("qrWTW.brandImage.enabled", qrWTWScreenSettings.brandImage.local !== '' && qrWTWScreenSettings.brandImage.local !== undefined);
        formData.append("qrWTW.footerThemeColor", qrWTWScreenSettings.footerThemeColor);
        formData.append("qrWTW.footerImage.file", qrWTWScreenSettings.footerImage.file);
        formData.append("qrWTW.footerImage.reset", qrWTWScreenSettings.footerImage.reset);
        formData.append("qrWTW.footerImage.enabled", qrWTWScreenSettings.footerImage.local !== '' && qrWTWScreenSettings.footerImage.local !== undefined);

        let rta = await CustomLoaderService.updateCustomLoaderConfig(campaignId, formData);
        setShowLoading(false)
        if(rta.status === 200){
            setNotification({ showNotification: true, propsNotification: { label: "OK", message:t('customLoaderLayout.applyChanges') } })
            setChangesSaved(true);
        }
        else{
            setNotification({ showNotification: true, propsNotification: { label: "Error", message:t('customLoaderLayout.errorChanges') } })
        }
    }
   
    async function discardChanges() {
        setShowLoading(true);
        (CustomLoaderService.applyInitialConfiguration(campaignId))
        .then(response=> response.json())
        .then(data => {
            setChangesSaved(false);
            setData(data);
            updateGeneralSettings({
                favicon: {reset: true, file: undefined, url: DEFAULT_FAVICON, local: DEFAULT_FAVICON},
            })
            updateLoadingSettings({
                headerImage: {file: undefined},
                background: {file: undefined},
                centerImage: {file: undefined},
                footerImage: {file: undefined},
            })
            updateQRMvarSettings({
                brandImage: {file: undefined},
                background: {file: undefined},
                footerImage: {file: undefined},
            })
            updateQRWTWSettings({
                brandImage: {file: undefined},
                background: {file: undefined},
                footerImage: {file: undefined},
            })
            setShowLoading(false)
        });
    }

    const openDialog = () => {
        setOpen({
            title: t('customLoaderLayout.discard'),
            subtitle: t('customLoaderLayout.discardMessage'),
            isOpen: true
        })
    }
    function closeNotification() {
        setNotification({ showNotification: false });
    }

    return (
        <>
            <div className='buttons-container'>
                <div className='apply-changes-button'>
                    <button disabled={changesSaved} className='save-button' variant='contained' onClick={save}> {t('customLoaderLayout.apply')}</button>
                </div>
                <button className='discard-changes-button' size='small' variant='outlined' onClick={openDialog}>{t('customLoaderLayout.discard')}</button>
            </div>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                functionHandler={discardChanges}
                buttonMessage={t("customLoaderLayout.yes")}
            />
            {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
    </>);
}

export default CustomLoaderSaveChanges;
