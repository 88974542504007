import React, {useState, useEffect, useContext} from 'react';
import {Dialog, DialogTitle, DialogContent} from '@rmwc/dialog';
import {
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableHeadCell,
    DataTableRow,
    DataTableBody,
    DataTableCell
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {IconButton} from '@rmwc/icon-button';
import {Icon} from '@rmwc/icon';
import {Elements} from 'react-stripe-elements';
import {Switch} from 'antd';
import {Toggle} from 'rsuite'
import {StripeProvider} from 'react-stripe-elements';
import {useTranslate} from 'react-polyglot';

import SubForm from './SubForm'
import AppContext from '../app/AppContext';
import {getExpirationDays} from '../../utils/user';
import getLocale from '../../utils/locale';

import './actionsDialog.scss';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import 'antd/dist/antd.css';

import '@material/list/dist/mdc.list.css';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import './subsDialog.scss';
import Header from "../layout/Header";
import {Icon as RIcon} from 'rsuite';

function SubsDialog(props) {
    const context = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [stage, setStage] = useState(2);
    const [paymentType, setPaymentType] = useState(1);
    const t = useTranslate();
    const [classStyle, setClasStyle] = useState('pro-button');
    const [textPayment, setTextPayment] = useState(t('pages.subscription.canCancelSubscription'));

    useEffect(() => {
        if (context.page.openSubDialog) {
            setOpen(true);
        }
        if (context.openSubPro) {
            setOpen(true);
        }
    }, [context.page.openSubDialog, context.openSubPro])

    function setContextToFalse() {
        context.setPage({...context.page, openSubDialog: false});
    }

    function onChangePayment(e) {
        let value = paymentType === 1 ? 2 : 1;
        if (value === 2) {
            setTextPayment(t('pages.subscription.annualSaving'));
            setClasStyle('pro-buttonBlue');
            setPaymentType(2);
        } else {
            setTextPayment(t('pages.subscription.canCancelSubscription'));
            setClasStyle('pro-button');
            setPaymentType(1);
        }
    }

    function openSubForm() {
        setStage(2);
    }

    function handleClose() {
        setContextToFalse();
        setStage(2);
        setOpen(false);
        context.setOpenSubPro(false)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={evt => {
                    setContextToFalse();
                    setOpen(false);
                }}
                className={`dialog-full ${props.hasNoZIndex ? 'zindexSelectP' : ''}`}
            >

                <Header inherited={true} hideSearch={true}/>
                <div style={{'display': 'flex', justifyContent: 'flex-end'}}>
                    <RIcon className={'iconCloseSubs'}
                           icon="close" onClick={handleClose}/>
                </div>
                <DialogContent>
                    <section className="subscription-container">
                        {stage === 1 &&

                        <div className="subscription-container-tittle">
                            <h2 className={`colorTitleSubs`}>{t('pages.endOfTrial.upgrade')} </h2>
                            <div className="switchCoA" onClick={onChangePayment}>
                                <div data-index-number={1}
                                     className={`monthlyS ${paymentType === 1 ? 'selectedTextPurp selectedBackgroundWhite' : ''} `}>
                                    {t('pages.subscription.monthlyPlan')}
                                </div>
                                <div data-index-number={2}
                                     className={`yearlyS ${paymentType === 2 ? 'selectedTextPurp selectedBackgroundWhite' : ''}`}>
                                    {t('pages.subscription.yearlyPlan')}
                                </div>
                            </div>
                        </div>
                        }

                        {/* {stage === 1 &&
                        <DataTable style={{width: '90%', marginLeft: '4.5%'}}>
                            <DataTableContent>
                                <DataTableHead>
                                    <DataTableRow>
                                        <DataTableHeadCell className="borderless"></DataTableHeadCell>
                                        <DataTableHeadCell
                                            className="subs-free-table"
                                        >
                                            <p className="subs-table-title">
                                                {t('pages.subscription.basicPlan')}
                                            </p>
                                            <div className={'paymentCurrencyContainer'}>

                                                <p className={'currencyP'}></p><p
                                                className="subs-table-pro">{t('pages.account.subscription.labels.free')}</p>

                                            </div>
                                            <p className="subs-table-trial">
                                                {getExpirationDays(context.userData.created_at) <= 0 ?
                                                    `${t('pages.account.subscription.labels.expired')}`
                                                    : `${t('sidebar.footer.title')} ${getExpirationDays(context.userData.created_at)} ${t('pages.account.subscription.labels.days')}`}
                                            </p>
                                        </DataTableHeadCell>
                                        <DataTableHeadCell className="pro-block-sub-table">
                                            <p className="subs-table-title">
                                                {t('pages.subscription.professionalPlan')}
                                            </p>
                                            {paymentType === 1 && <div className={'paymentCurrencyContainer'}>

                                                <p className={'currencyP'}>USD</p><p
                                                className="subs-table-pro">{`${process.env.REACT_APP_PRICING_MONTHLY}/${t('pages.subscription.month')}`}      </p>

                                            </div>


                                            }
                                            {paymentType === 2 &&
                                            <div className={'paymentCurrencyContainer'}>

                                                <p className={'currencyP'}>USD</p><p
                                                className="subs-table-pro">{`${process.env.REACT_APP_PRICING_YEARLY}/${t('pages.subscription.year')}`}      </p>

                                            </div>

                                            }
                                            <div>
                                                <p className={paymentType === 2 ? "subs-table-cancel" : 'subs-table-normal'}>
                                                    {textPayment}
                                                </p>
                                            </div>
                                            <Button label={t('pages.subscription.upgradePlan')} raised
                                                    className={`${classStyle} marginButtonPro`}
                                                    onClick={openSubForm}/>
                                        </DataTableHeadCell>
                                    </DataTableRow>
                                </DataTableHead>
                                <DataTableBody>

                                    <DataTableRow className={'subs-table-row'}>
                                        <DataTableCell>{t('pages.subscription.withoutCreditCard')}</DataTableCell>
                                        <DataTableCell alignMiddle className="center"> <Icon icon="done"
                                                                                             className="checkmark "/>
                                        </DataTableCell>
                                        <DataTableCell alignMiddle className="subs-table-row-pro center"><Icon
                                            icon="done" className="checkmark "/> </DataTableCell>
                                    </DataTableRow>
                                    <DataTableRow className={'subs-table-row'}>
                                        <DataTableCell>{t('pages.subscription.unlimitedExperiences')}</DataTableCell>
                                        <DataTableCell alignMiddle className="center"><Icon icon="done"
                                                                                            className="checkmark "/>
                                        </DataTableCell>
                                        <DataTableCell alignMiddle className="subs-table-row-pro center"><Icon
                                            icon="done" className="checkmark "/> </DataTableCell>
                                    </DataTableRow>
                                    <DataTableRow className={'subs-table-row'}>
                                        <DataTableCell>{t('pages.subscription.publishDrafts')}</DataTableCell>
                                        <DataTableCell alignMiddle className="center"><Icon icon="done"
                                                                                            className="checkmark "/>
                                        </DataTableCell>
                                        <DataTableCell alignMiddle className="subs-table-row-pro center"><Icon
                                            icon="done" className="checkmark "/> </DataTableCell>
                                    </DataTableRow>
                                    <DataTableRow className={'subs-table-row'}>
                                        <DataTableCell>{`1000 ${t('pages.subscription.views')} / ${t('pages.subscription.monthlyScan')}`}</DataTableCell>
                                        <DataTableCell alignMiddle className="center"></DataTableCell>
                                        <DataTableCell alignMiddle className="subs-table-row-pro center"><Icon
                                            icon="done" className="checkmark "/> </DataTableCell>
                                    </DataTableRow>
                                    <DataTableRow className={'subs-table-row'}>
                                        <DataTableCell>{t('pages.subscription.accessReportsMetrics')}</DataTableCell>
                                        <DataTableCell></DataTableCell>
                                        <DataTableCell alignMiddle className="subs-table-row-pro center"><Icon
                                            icon="done" className="checkmark "/> </DataTableCell>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <DataTableCell>{t('pages.subscription.helpCenter')}</DataTableCell>
                                        <DataTableCell alignMiddle className="center"><Icon icon="done"
                                                                                            className="checkmark "/>
                                        </DataTableCell>
                                        <DataTableCell alignMiddle className="subs-table-row-pro center"><Icon
                                            icon="done" className="checkmark "/> </DataTableCell>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <DataTableCell>{t('pages.subscription.personalSupport')}</DataTableCell>
                                        <DataTableCell></DataTableCell>
                                        <DataTableCell alignMiddle className="subs-table-row-pro center"><Icon
                                            icon="done" className="checkmark "/></DataTableCell>
                                    </DataTableRow>

                                </DataTableBody>
                            </DataTableContent>
                        </DataTable>


                        } */}
                        {stage === 2 &&
                        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>

                            <Elements locale={getLocale()}>
                                <SubForm paymentType={paymentType}/>
                            </Elements>

                        </StripeProvider>

                        }

                    </section>


                </DialogContent>

            </Dialog>
        </>
    )
}

export default SubsDialog;