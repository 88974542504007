import React, {useState, useEffect, useContext} from 'react';
import {useTranslate} from 'react-polyglot';
import {Auth} from 'aws-amplify';
import {navigate, Location} from '@reach/router';

import Icon from '../icon';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {deleteCookie} from '../../utils/cookie';
import user, {getExpirationDays} from '../../utils/user';
import Searchbar from '../Searchbar/Searchbar';
import {Avatar} from 'rsuite'

import {
    HeaderWrapper,
    ImgContainer,
    SearchContainer,
    MenuContainer,
    SimpleMenuWithStyles,
    SimpleMenuItem,
    UserBoxWrapper,
    Logo,
    SearchContainerBox,
    SearchLabel,
    ButtonMembership,
    TextLabelMembership
} from './HeaderStyles';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import AppContext from "../app/AppContext";

function Header(props) {
    const context = useContext(AppContext);
    const t = useTranslate();
    const [userData, setUserData] = useState(null);
    const [searchhValue, setSearchhValue] = useState('');

    const [open, setOpen] = useState(false);
    const [activeSearch, setActiveSearch] = useState(false);
    useEffect(() => {
        user().then((us) => {
            setUserData(us.attributes);
        })
    }, []);

    async function logout() {
        await Auth.signOut();
        deleteCookie();
        localStorage.removeItem('lang');
        window.location.href = '/';
    }

    const getUserIdFromUrl = () => {
        let paths = window.location.pathname.split('/');
        let userId = paths.length > 1 ? paths[2] : '';
        let hasUser = paths.length > 1 && paths[1] === 'user';
        if (hasUser) {
            return userId
        }
        return ''
    };

    function keydown(event) {
        if (event.key === 'Enter') {
            if (searchhValue !== '') {
                setActiveSearch(false);

                if (getUserIdFromUrl().length == 0){
                    navigate('/search', {
                        state: {text: searchhValue}
                    });
                }else{
                    navigate('/user/'+getUserIdFromUrl()+'/search', {
                        state: {text: searchhValue}
                    });
                }
            }
        }
    }

    const getUserTypeColor = () => {
        if (context.userGroups.find(e => e === 'CamonappUsers' || e === 'FreelanceUsers') !== undefined) {
            return 'NORMAL_USER'
        }
        if (context.userGroups.find(e => e === 'FreelancePaidUsers') !== undefined) {
            return 'PRO'
        }
        if (context.userGroups.find(e => e === 'CamonappAdminUsers') !== undefined) {
            return 'ADMIN'
        }
    };

    const getTextType = () => {
        if (context.userGroups.find(e => e === 'CamonappUsers' || e === 'FreelanceUsers') !== undefined) {
            return `${getExpirationDays(context.userData.created_at)} ${t('sidebar.footer.time')}`;
        }
        if (context.userGroups.find(e => e === 'FreelancePaidUsers') !== undefined) {
            return 'PRO'
        }
        if (context.userGroups.find(e => e === 'CamonappAdminUsers') !== undefined) {
            return 'ADMIN'
        }
    };

    const getMenuOptionsByRole = () => {
        let options = [];
        options.push(
            <SimpleMenuItem hasBottomBorder={true} onClick={() => {
                context.setPage({...context.page, openSubDialog: false});
                navigate('/account/profile')
            }}>
                <div>
                    {t('header.menu.profile')}
                    {(context.userGroups.find(e => e === 'CamonappUsers') !== undefined) ? null :
                    <ButtonMembership colorType={getUserTypeColor()}>
                        <TextLabelMembership>
                            {getTextType()}
                        </TextLabelMembership>
                    </ButtonMembership>}
                </div>
            </SimpleMenuItem>
        );

        if (context.userGroups.find(e => e === 'ProfessorsUsers') !== undefined) {
            options.push(
                <SimpleMenuItem hasBottomBorder={false} onClick={() => {
                    context.setPage({...context.page, openSubDialog: false});
                    navigate('/account/students')
                }}>
                    {t('pages.account.tabs.students')}
                </SimpleMenuItem>
            );
        }

        if (context.userGroups.find(e => e === 'CamonappUsers') !== undefined) {
            options.push(
                <SimpleMenuItem hasBottomBorder={false} onClick={() => {
                    context.setPage({...context.page, openSubDialog: false});
                    navigate('/account/users')
                }}>
                    {t('header.menu.users')}
                </SimpleMenuItem>
            );
            options.push(<SimpleMenuItem hasBottomBorder={false} onClick={() => {
                context.setPage({...context.page, openSubDialog: false});
                navigate('/account/publishes')
            }}>
                {t('header.menu.published')}
            </SimpleMenuItem>)
        }
        if (context.userGroups.find(e => e === 'FreelanceUsers') !== undefined) {
            options.push(<SimpleMenuItem backgroundPro={true} hasBottomBorder={false} onClick={() => {
                context.setOpenSubPro(true)
            }}>
                {t('sidebar.footer.action')}
            </SimpleMenuItem>);
        }
        if (context.userGroups.find(e => e === 'CamonappAdminUsers') !== undefined) {
            options.push(<SimpleMenuItem hasBottomBorder={false} onClick={() => {
                context.setPage({...context.page, openSubDialog: false});
                navigate('/account/companies')
            }}>
                {t('header.menu.companies')}
            </SimpleMenuItem>);
            options.push(<SimpleMenuItem hasBottomBorder={false} onClick={() => {
                context.setPage({...context.page, openSubDialog: false});
                navigate('/account/users')
            }}>
                {t('header.menu.users')}


            </SimpleMenuItem>);


            options.push(<SimpleMenuItem hasBottomBorder={false} onClick={() => {
                context.setPage({...context.page, openSubDialog: false});
                navigate('/account/educationals')
            }}>
                {t('pages.account.tabs.educationals')}


            </SimpleMenuItem>);
            options.push(<SimpleMenuItem hasBottomBorder={false} onClick={() => {
                context.setPage({...context.page, openSubDialog: false});
                navigate('/account/professors')
            }}>
                {t('pages.account.tabs.professors')}


            </SimpleMenuItem>);
            options.push(<SimpleMenuItem hasBottomBorder={false} onClick={() => {
                context.setPage({...context.page, openSubDialog: false});
                navigate('/account/students')
            }}>
                {t('pages.account.tabs.students')}
            </SimpleMenuItem>);
        }

        if (context.userGroups.find(e => e === 'FreelanceUsers' || e === 'FreelancePaidUsers') !== undefined) {
            options.push(
                <SimpleMenuItem hasBottomBorder={false} onClick={(evt) => {
                    evt.preventDefault();
                    window.open('https://camonapp.com/terminos-y-condiciones/', '_blank');
                    window.focus();
                }}>
                    {t('help.termsAndConditions')} <OpenInNewIcon style={{position: 'absolute', right: '10px'}} fontSize='small'></OpenInNewIcon>
                </SimpleMenuItem>
            );
            options.push(
                <SimpleMenuItem hasBottomBorder={true} onClick={(evt) => {
                    evt.preventDefault();
                    window.open('https://camonapp.com/politica-de-privacidad/', '_blank');
                    window.focus();
                }}>
                    {t('help.privacyPolicies')} <OpenInNewIcon style={{position: 'absolute', right: '10px'}} fontSize='small'></OpenInNewIcon>
                </SimpleMenuItem>
            );
        }

        options.push(<SimpleMenuItem onClick={logout}>{t('header.logout')}</SimpleMenuItem>);
        return options;
    };

    return (
        <HeaderWrapper inherited={props.inherited}>
            <ImgContainer>
                <Logo alt="logo" src="/logo_studio.svg"/>
            </ImgContainer>
            <SearchContainer>
                {!props.hideSearch &&
                <Location>
                    {({location}) =>
                        (
                            <Searchbar
                                setActiveSearch={setActiveSearch}
                                keydown={keydown}
                                searchValue={searchhValue}
                                setSearchValue={setSearchhValue}
                                location={location}
                            />)}
                </Location>
                }
            </SearchContainer>
            <MenuContainer>
                <SimpleMenuWithStyles
                    anchorCorner={'bottomStart'}
                    handle={
                        <UserBoxWrapper onClick={() => {
                            setOpen(!open)
                        }}>
                            <Avatar style={{width: '24px', height: '24px', marginRight: '4px'}}
                                    circle>{userData && userData.email ? userData.email.charAt().toUpperCase() : ''}</Avatar>
                            <span className={'elipsisText'}>{userData && userData.email}</span>
                            <Icon name={!open ? 'caret-down' : 'caret-up'}/>
                        </UserBoxWrapper>
                    }>
                    {getMenuOptionsByRole()}
                </SimpleMenuWithStyles>
            </MenuContainer>
            {
                activeSearch &&
                <SearchContainerBox>
                    <SearchLabel>{t('header.search')}</SearchLabel>
                </SearchContainerBox>
            }
        </HeaderWrapper>

    )
}

export default Header;
