import React, {useState, useContext, useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import '@rmwc/badge/badge.css';
import '../CustomLoaderMenu.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ColorPicker from '../../colorPicker/ColorPicker';
import InputFileDragAndDrop from '../../inputFileDragAndDrop/InputFileDragAndDrop'
import '@material/textfield/dist/mdc.textfield.css';
import { CustomLoaderContext } from '../../../context/CustomLoaderContext';
import {
    FontSizeOutlined,
    DownloadOutlined,
    PictureOutlined
  } from '@ant-design/icons';
import { Checkbox, TextField } from '@mui/material';

import { AVAILABLE_FONTS, getFontFamily } from '../availableFonts';
import CheckPermision from '../../checkPermision/checkPermision';
import { userGroupsObject } from '../../../utils/userGroups';


function QRScreenMVAR(props) {

    const t = useTranslate();
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })
    const {
            qrMvarScreenSettings,
            updateQRMvarSettings,
            setChangesSaved,
        } = useContext(CustomLoaderContext);


    const [textSettingsOpen, setTextSettingsOpen] = useState(false);
    const [footerSettingsOpen, setFooterSettingsOpen] = useState(false);
    const [brandLogoSettingsOpen, setBrandLogoSettingsOpen] = useState(false);
    const [backgroundSettingsOpen, setBackgroundSettingsOpen] = useState(false);

    const [buttonSettingsOpen, setButtonSettingsOpen] = useState(false);

    // footer logo
    const flThemeColors = ["black", "white"];

    const closeAll = () => {
        setTextSettingsOpen(false);
        setBrandLogoSettingsOpen(false);
        setFooterSettingsOpen(false);
        setBackgroundSettingsOpen(false);
        setButtonSettingsOpen(false);
    }
    
    const handleMenuClick = (currentState, openSetter) => {
        closeAll();
        openSetter(!currentState)
    }
    
    const handleUpdateSettings = (newSettings) => {
        setChangesSaved(false);
        updateQRMvarSettings(newSettings)
    }

    useEffect(() => {
        if (qrMvarScreenSettings.background.file) {
            updateQRMvarSettings({
                background: {
                    isGradiant: false,
                    isGrid: false
                }
            })
        }
    }, [qrMvarScreenSettings.background.file])


    return (
        <List>
            <ListItem button onClick={() => handleMenuClick(brandLogoSettingsOpen, setBrandLogoSettingsOpen)} className={brandLogoSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <PictureOutlined src={`/images/reports-hovered.svg`} style={{
                                            marginLeft: '8px',
                                            marginRight: '8px',
                                            width: '16px',
                                            height: '16px'
                                        }}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.qr.brandImage')} />
            </ListItem>
            <Collapse in={brandLogoSettingsOpen} unmountOnExit>
                <ListItem>
                    <InputFileDragAndDrop
                        id="brandLogo"
                        acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                        setOpen={setOpen}
                        handleURL={(url) => handleUpdateSettings({brandImage: {url}})}
                        handleFile={(file) => handleUpdateSettings({brandImage: {file}})}
                        defaultFile={qrMvarScreenSettings.brandImage.local}
                        onFileLoaded={(filePath) => {
                            handleUpdateSettings({
                                brandImage: {
                                    local: filePath,
                                    reset: false,
                                }
                            })
                        }}
                        onQuitFile={() => {
                            handleUpdateSettings({
                                brandImage: {
                                    local: undefined,
                                    file: undefined,
                                    reset: true
                                }
                            })
                        }}
                    ></InputFileDragAndDrop>
                </ListItem>
            </Collapse>

            <ListItem button onClick={() => handleMenuClick(textSettingsOpen, setTextSettingsOpen)} className={textSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <FontSizeOutlined style={{
                                            marginLeft: '8px',
                                            marginRight: '8px',
                                            width: '16px',
                                            height: '16px'
                                        }} />
                <ListItemText primary={t('customLoaderSidebar.menu.screens.qr.fonts')} />
            </ListItem>
            <Collapse in={textSettingsOpen}>
                    <ListItem>
                    <ColorPicker defaultColor={qrMvarScreenSettings.fonts.textColor} handleColor={(textColor) => handleUpdateSettings({fonts: {textColor}})}></ColorPicker>
                </ListItem>
                <ListItem className="text-field">
                    <select
                        className="select-header-font"
                        value={qrMvarScreenSettings.fonts.family}
                        style={{
                            fontFamily: getFontFamily(qrMvarScreenSettings.fonts.family),
                            fontWeight: 'normal'
                        }}
                        onChange={(e) => handleUpdateSettings({fonts: {family: e.target.value}})}
                        label=" "
                    >
                        {
                            AVAILABLE_FONTS.map((font) => {
                                return (
                                    <option 
                                        key={`font-${font.name}`} 
                                        value={font.name}
                                        style={{ fontFamily: font.normal.fontFamily, fontWeight: font.normal.fontWeight, fontSize: '16px', padding: '8px' }}
                                    >
                                        {font.name}
                                    </option>
                                )
                            })
                        }
                    </select>
                </ListItem>
            </Collapse>

            <ListItem button onClick={() => handleMenuClick(footerSettingsOpen, setFooterSettingsOpen)} className={footerSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <DownloadOutlined style={{marginLeft: '8px', marginRight: '8px', width: '16px', height: '16px'}}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.qr.footer')} />
            </ListItem>
            <Collapse in={footerSettingsOpen} className='footer-container'>
                <ListItem className="text-field">
                    <select
                        className="select-footer-theme-color"
                        value={qrMvarScreenSettings.footerThemeColor}
                        onChange={(e)=>handleUpdateSettings({footerThemeColor: e.target.value})}
                        label=" "
                    >
                        {
                            flThemeColors.map((l) => {
                                return <option key={`theme-${l}`} value={l}>{t(`customLoaderSidebar.menu.screens.qr.${l}`)}</option>
                                
                            })
                        }
                    </select>
                </ListItem>
                <ListItem>
                    <InputFileDragAndDrop
                        id="loadingFooterLogo"
                        acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                        setOpen={setOpen}
                        handleURL={(url)=>handleUpdateSettings({footerImage: {url}})}
                        handleFile={(file)=>handleUpdateSettings({footerImage: {file}})}
                        defaultFile={qrMvarScreenSettings.footerImage.local}
                        onFileLoaded={(filePath) => {
                            handleUpdateSettings({
                                footerImage: {
                                    local: filePath,
                                    reset: false
                                }
                            })
                        }}
                        onQuitFile={() => {
                            handleUpdateSettings({
                                footerImage: {
                                    local: undefined,
                                    file: undefined,
                                    reset: true
                                }
                            })
                        }}
                    ></InputFileDragAndDrop>
                </ListItem>
            </Collapse>


            <ListItem button onClick={() => handleMenuClick(backgroundSettingsOpen, setBackgroundSettingsOpen)} className={backgroundSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                <PictureOutlined src={`/images/reports-hovered.svg`} style={{
                                            marginLeft: '8px',
                                            marginRight: '8px',
                                            width: '16px',
                                            height: '16px'
                                        }}/>
                <ListItemText primary={t('customLoaderSidebar.menu.screens.qr.background')} />
            </ListItem>
            <Collapse in={backgroundSettingsOpen} unmountOnExit>
                <ListItem>
                    <div className='background-item-container'>
                        <ColorPicker
                            disabled={qrMvarScreenSettings.background.local}
                            defaultColor={qrMvarScreenSettings.background.color}
                            handleColor={(color)=>handleUpdateSettings({background: {color}})}>
                        </ColorPicker>

                        { qrMvarScreenSettings.background.isGradiant &&
                            <ColorPicker
                                disabled={qrMvarScreenSettings.background.local}
                                defaultColor={qrMvarScreenSettings.background.gradiantColor}
                                handleColor={(gradiantColor)=>handleUpdateSettings({background: {gradiantColor}})}>
                            </ColorPicker>
                        }
                        <div>
                            <Checkbox
                                disabled={qrMvarScreenSettings.background.local}
                                checked={qrMvarScreenSettings.background.isGradiant}
                                onChange={(e)=>handleUpdateSettings({background: {isGradiant: e.target.checked}})}/> {t('customLoaderSidebar.menu.screens.qr.gradiant')}
                        </div>
                        <div>
                            <Checkbox
                                disabled={qrMvarScreenSettings.background.local}
                                checked={qrMvarScreenSettings.background.isGrid}
                                onChange={(e)=>handleUpdateSettings({background: {isGrid: e.target.checked}})}/>{t('customLoaderSidebar.menu.screens.qr.grid')}                            
                        </div>
                        <InputFileDragAndDrop
                            id="backgroundFile"
                            acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                            setOpen={setOpen}
                            handleURL={(url) => handleUpdateSettings({background: {url}})}
                            handleFile={(file) => handleUpdateSettings({background: {file}})}
                            defaultFile={qrMvarScreenSettings.background.local}
                            onFileLoaded={(filePath) => {
                                handleUpdateSettings({
                                    background: {
                                        local: filePath,
                                        //file: file => handleUpdateSettings({background: {file}}),
                                        reset: false,
                                        isGrid: false,
                                    }
                                })
                            }}
                            onQuitFile={() => {
                                handleUpdateSettings({
                                    background: {
                                        file: undefined,
                                        local: undefined,
                                        reset: true,
                                        isGrid: true,
                                    }
                                })
                            }}
                        ></InputFileDragAndDrop>
                    </div>
                </ListItem>
            </Collapse>
            <CheckPermision component={<>
                <ListItem button onClick={() => handleMenuClick(buttonSettingsOpen, setButtonSettingsOpen)} className={textSettingsOpen ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <FontSizeOutlined style={{
                        marginLeft: '8px',
                        marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }} />
                    <ListItemText primary={t('customLoaderSidebar.menu.screens.qr.button')} />
                </ListItem>

                <Collapse in={buttonSettingsOpen}>
                    <ListItem>
                        <div className='label-color-picker'>{t("customLoaderSidebar.menu.screens.qr.buttonTextColor")}</div>
                        <ColorPicker defaultColor={qrMvarScreenSettings.button.textColor} handleColor={(textColor) => handleUpdateSettings({button: {textColor}})}></ColorPicker>
                    </ListItem>
                    <ListItem>
                        <div className='label-color-picker'>{t("customLoaderSidebar.menu.screens.qr.buttonBgColor")}</div>
                        <ColorPicker defaultColor={qrMvarScreenSettings.button.backgroundColor} handleColor={(backgroundColor) => handleUpdateSettings({button: {backgroundColor}})}></ColorPicker>
                    </ListItem>
                </Collapse>
                </>}
                groups={[
                    userGroupsObject.CamonappAdminUsers,
                    userGroupsObject.CamonappSalesUsers,
                    userGroupsObject.CamonappUsers,
                    userGroupsObject.CamonappUsersCommon,
                    userGroupsObject.CompanyUsers
                ]}    
            ></CheckPermision>
        </List>
    );
}

export default QRScreenMVAR;
