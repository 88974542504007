import React, {useState, useContext, forwardRef, useReducer,useEffect} from 'react';
import {Card, CardPrimaryAction, CardMedia, CardActions, CardActionIcon} from '@rmwc/card';
import {Badge} from '@rmwc/badge';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';
import {navigate} from '@reach/router';
import {useTranslate} from 'react-polyglot';
import {Popover, Whisper} from "rsuite";

import useObserver from '../../hooks/useObserver';
import {CardWrapper} from './ExperienceStyles';
import Notification from '../notification/notification';
import Appcontext from '../app/AppContext';
import ElipsisMenu from '../elipsisMenu';
import ExperienceService from '../../services/Experience';
import ExpName from '../actions/ExpName';
import Sure from '../../pages/Profile/Sure';
import Modal from '../modal/modal';
import ExperienceType from './ExperienceType';
import ChangeTarget from '../actions/changeTarget';
import CloneExp from '../actions/CloneExp';
import SeeTarget from '../actions/SeeTarget';
import MigExp from '../actions/MigExp';
import DeleteExp from '../actions/DeleteExp';
import CheckPermission from '../checkPermision/checkPermision';
import {userGroupsObject} from '../../utils/userGroups';
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import getLocale from '../../utils/locale';

import './experienceCard.scss';
import '@material/card/dist/mdc.card.css';
import '@material/button/dist/mdc.button.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@rmwc/tooltip/tooltip.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/badge/badge.css';
import {QRDialogContext} from "../../context/QRDialogContext";
import {ChangeTargetDialogContext} from "../../context/ChangeTargetDialogContext";
import {ChangeNameDialogContext} from "../../context/ChangeNameDialogContext";

import ShowLoadingContext from '../../context/ShowLoadingContext';
import moment from 'moment';
import ChangeLoading from '../actions/ChangeLoading8thwall';
import StorageService from '../../services/Storage';
import ChangeToAd from '../actions/ChangeToAd';
import CloneDemoExp from '../actions/CloneDemoExp';
import SlideshowDemoExperience from './SlideshowDemoExperience';


const ExperienceCard = forwardRef((props, ref) => {

    const context = useContext(Appcontext);
    const [exp, setExp] = useState(false);
    const [cmpDataObj, setCmpDataObj] = useState({});
    const [isAd,setIsAd] = useState(false)
    const [show, element] = useObserver();
    const locale = getLocale() || 'en';
    const { 
        setShowLoading, 
        setOverlayStyle 
    } = useContext(ShowLoadingContext)

    const getPopover = (name) => {
        return <Popover>
            <p>{name}</p>
        </Popover>
    };

    const [{ showNotification, propsNotification }, setNotification] = useReducer(
        (prevState, currState) => ({ ...prevState, ...currState }),
        { showNotification: false, propsNotification: {} });
    const [avoidCacheImage, setAvoidCacheImage] = useState((new Date()).getTime());

    const t = useTranslate();

    const isFreelance = () => context.userGroups.includes(userGroupsObject.FreelanceUsers);
    const isFreelancePaid = () => context.userGroups.includes(userGroupsObject.FreelancePaidUsers);

    async function editExpName(value) {
        try {
            await ExperienceService.update(value, props.experience.id);
            if (context.page.refetch) {
                context.page.refetch()
            }
        } catch (error) {
            console.log(error);
        } finally {
            setExp(false);
        }
    }

    async function changeTarget(params) {
        try {

            let formData = new FormData();
            formData.append('target', params);
            formData.append('id', this.componentKey);

            await ExperienceService.update(formData, this.componentKey);

            setAvoidCacheImage((new Date()).getTime());
            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.changeTarget') }
            })

        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
        }
    }

    async function clone(params) {
        try {
            let formData = {
                target: params.value,
                name: params.name,
                from: 'experience',
                id: params.key,
                experienceTypeId: props.experience.experienceTypeId,
                demoMode: params.isDemo
            };
            if (params.isDemo) {
                formData['campaign[id]'] = params.campaign.id
                formData['campaign[name]'] = params.campaign.name
            }
            context.setPage({...context.page, loading: true});
            const response = await ExperienceService.clone(formData);
            context.setPage({...context.page, loading: false});
            if (context.page.refetch) {
                context.page.refetch()
            }
            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.clone') }
            })
            if (params.isDemo && response.campaign?.id) {
                navigate(`/campaign/${response.campaign.id}`)
            }
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
        }

    }

    async function activateExp() {
        try {
            context.setPage({...context.page, loading: true});
            const exp = this.componentKey;
            await ExperienceService.expirate(exp,null);
            await ExperienceService.activate(exp)

            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.activate') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }
            // window.location.reload();
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
            context.setPage({...context.page, loading: false});
        }
    }

    async function deactivateExp() {
        try {
            context.setPage({...context.page, loading: true});
            const exp = this.componentKey;
            const date = moment()
            await ExperienceService.deactivate(exp)
            await ExperienceService.expirate(exp,date);
            
            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.deactivate') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }

            // window.location.reload();
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
            context.setPage({...context.page, loading: false});
        }
    }

    async function enableVRModeCompatibility() {
        try {
            context.setPage({...context.page, loading: true});
            const exp = this.componentKey;
            const result = await ExperienceService.enableVRMode(exp);
            props.experience.vr_mode_compatible = result.vr_mode_compatible;

            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.enableVRMode') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
            context.setPage({...context.page, loading: false});
        }
    }

    async function disableVRModeCompatibility() {
        try {
            context.setPage({...context.page, loading: true});
            const exp = this.componentKey;
            await ExperienceService.disableVRMode(exp)

            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.disableVRMode') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }

            // window.location.reload();
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
            context.setPage({...context.page, loading: false});
        }
    }

    async function migrate(params) {
        try {
            const companyId = context.userData.company.id;
            const exp = this.componentKey;
            const campaignId = params.campaign.id;
            setShowLoading(true)
            setOverlayStyle("loading-overlay-full")
            await ExperienceService.migrate(campaignId,companyId, exp);
            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.migrate') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }

        } catch (error) {
            console.log(error);
        } finally {
            setExp(false);
            setShowLoading(false)
        }
    }

    function handleMenuClick(e) {
        e.stopPropagation();
        let actionsDialogData = {};
        switch (this.key) {
            case 'open':
                goToEditor();
                break;
            case 'show':
                actionsDialogData = {
                    title: t('experiences.actions.seeTarget'),
                    componentData: props.experience,
                    component: SeeTarget,
                    componentKey: props.experience.id,
                    action: () => {
                    },
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'change-target':
                actionsDialogData = {
                    title: t('experiences.actions.changeTarget'),
                    componentData: props.experience.name,
                    component: ChangeTarget,
                    //si se desea manejar logica de botones interna del componente nos aseguramos de colocar butonless
                    buttonLess: true,
                    componentKey: props.experience.id,
                    action: changeTarget,
                };
                setExp(true);

                setCmpDataObj({...actionsDialogData})
                //context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });

                break;
            case 'edit':
                actionsDialogData = {
                    title: t('experiences.actions.edit'),
                    componentData: props.experience.name,
                    component: ExpName,
                    componentKey: props.experience.id,
                    action: editExpName,
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                //context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });

                break;
            case 'activate':

                actionsDialogData = {
                    title: props.experience.active ? t('experiences.actions.deactive') : t('experiences.actions.active'),
                    componentData: props.experience.name,
                    component: Sure,
                    componentKey: props.experience.id,
                    action: props.experience.active ? deactivateExp : activateExp,
                };
                // context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'clone':
                actionsDialogData = {
                    title: t('experiences.actions.cloneExp'),
                    componentData: {name: props.experience.name, hasTarget: !!props.experience.target},
                    component: CloneExp,
                    componentKey: props.experience.id,
                    action: clone,
                    buttonLess: true
                };
                // context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'cloneDemo':
                actionsDialogData = {
                    title: t('experiences.actions.cloneExp'),
                    componentData: {...props.experience, name: props.experience.name, hasTarget: !!props.experience.target, isDemo: true, showNotice: !!this.showNotice},
                    component: CloneDemoExp,
                    componentKey: props.experience.id,
                    action: clone,
                    buttonLess: true
                };
                // context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'migrate':
                actionsDialogData = {
                    title: t('experiences.actions.migExp'),
                    componentData: props.experience,
                    component: MigExp,
                    componentKey: props.experience.id,
                    action: migrate,
                };
                // context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'reports':
                navigate(`${process.env.REACT_APP_REPORTS_V2}/goto?company_id=${props.experience.companyId}&campaign_id=${props.experience.campaign.id}&experience_id=${props.experience.id}`);
                break;
            case 'delete':
                actionsDialogData = {
                    title: t('experiences.actions.removeExp'),
                    componentData: props.experience.name,
                    component: DeleteExp,
                    componentKey: props.experience.id,
                    action: deleteExperience,
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'changeLoading':
                actionsDialogData = {
                    title: t('actions.changeLoading'),
                    componentData: {
                        publicAlias: props.experience.link.public_alias,
                        experienceId: props.experience.id,
                    },
                    component: ChangeLoading,
                    componentKey: props.experience.id,
                    buttonLess: true,
                    action: ()=>{
                        setExp(false);
                    }
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData});         
                break;
            case 'experienceAd':
                actionsDialogData = {
                    title: props.experience.is_ad ? t('actions.converToNormal') : t('actions.converToAd') ,
                    componentData: {
                        experienceId: props.experience.id,
                        isAd: props.experience.is_ad,
                        experienceType: props.experience.experienceTypeId,
                        publicAlias: props.experience.link.public_alias
                    },
                    component: ChangeToAd,
                    componentKey: props.experience.id,
                    buttonLess: true,
                    action: ()=>{
                        setExp(false);
                    }
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData});         
                break;
            case 'enableVRMode':
                actionsDialogData = {
                    title: props.experience.vr_mode_compatible ? t('experiences.actions.disableVRMode') : t('experiences.actions.enableVRMode'),
                    componentData: props.experience.name,
                    component: Sure,
                    componentKey: props.experience.id,
                    action: props.experience.vr_mode_compatible ? disableVRModeCompatibility : enableVRModeCompatibility,
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            default:
                break;
        }
    }

    async function deleteExperience() {
        try {
            const response = await ExperienceService.delete(this.componentKey);
            if (context.page.refetch) {
                context.page.refetch()
            }
            return response;

        } catch (error) {
            throw new Error(error);
        } finally {
            setExp(false);
        }
    }

    function getUrl(file) {
        if (file.includes('http')) {
            return file;
        } else {
            const fileArray = props.experience.target.thumb.split('/').reverse();
            return `http://localhost:3008/targets/${fileArray[1]}/${fileArray[0]}`
        }
    }

    function getHermansUrl() {
        let baseUrl = `${process.env.REACT_APP_EDITOR_DOMAIN}?id=${props.experience.id}`;
        let paths = window.location.pathname.split('/');
        let userId = paths.length > 1 ? paths[2] : '';
        let hasUser = paths.length > 1 && paths[1] === 'user';
        if (hasUser) {
            baseUrl += `&user=${userId}`
        }
        return baseUrl
    }

    function goToEditor() {
        let baseUrl = `${process.env.REACT_APP_EDITOR_DOMAIN}?id=${props.experience.id}`;
        if (props.accessFromEditor) {
            let paths = window.location.pathname.split('/');
            let userId = paths.length > 1 ? paths[2] : '';
            let hasUser = paths.length > 1 && paths[1] === 'user';


            let campaignId = paths.length > 1 ? paths[4] : '';
            let hasCampaign = paths.length > 1 && paths[3] === 'project';
            if (hasUser) {
                baseUrl += `&user=${userId}`
            }
            if (hasCampaign) {
                baseUrl += `&campaign=${campaignId}`;
            }
        }
        window.open(baseUrl, "_self")
    }

    function isPublished(exp) {
        return exp.published.web !== null || exp.published.mobile !== null
    }

    function isPublishedToProd() {
        return !!props.experience.published.prod
    }
   
    const canUseVRMode = () => {
        return !isFreelance();
    }

    const canSeeReport = () => {
        if (context.userGroups.includes(userGroupsObject.CompanyUsers)) {
            return context.userData.company ? context.userData.company.canSeeReport : false;
        }
        return true;
    };

    const isEditor = () => {
        if (context.userGroups.find(e => e === 'CamonappUsers') !== undefined) {
            return 'Editor'
        }
    };

    const isAdmin = () => {
        if (context.userGroups.find(e => e === 'CamonappAdminUsers') !== undefined) {
            return 'Admin'
        }
    };

    //context for migration
    const {setExperienceId, setOpenMigrateExperienceDialog} = useContext(MigrateUserDialogContext);
    const openMigrationExperienceAdmin = () => {
        setExperienceId(props.experience.id);
        setOpenMigrateExperienceDialog(true)
    };
    const {setOpenQRDialog, setDialogData} = useContext(QRDialogContext);
    const handleQRDialog = () => {
        setDialogData({
            published: !!props.experience.published.prod,
            url: props.experience.public_url
        });
        setOpenQRDialog(true)
    };
    const {setOpenChangeTargetDialog, setPayload, payload} = useContext(ChangeTargetDialogContext);
    const handleChangeTarget = () => {
        setPayload({...payload, ...{id: props.experience.id}});
        setOpenChangeTargetDialog(true);
    };

    const {changeNameData, setDialogChangeNameData, setOpenChangeDialog} = useContext(ChangeNameDialogContext);
    const handleChangeNameDialog = () => {
        setDialogChangeNameData({...changeNameData, ...{name: props.experience.name, id: props.experience.id}});
        setOpenChangeDialog(true);
    };

    const [visible, setVisible] = useState(props.experience.target && props.experience.target.isOffline);

    function closeNotification() {
        setNotification({ showNotification: false })
    }

    return (
        <CardWrapper ref={element}>
            {show &&
            <Card style={{width: '232px', height: '200px'}}
                  className={props.className + ` experience-card ${props.experience.active ? 'activeExp' : 'inactiveExp'}`}>
                { 
                    props.isDemo ?
                    <CardPrimaryAction>
                        <SlideshowDemoExperience
                            experienceUrl={props.experience.public_url}
                            onClick={handleMenuClick.bind({key: 'cloneDemo', showNotice: true})}
                        >
                        </SlideshowDemoExperience>
                        <ExperienceType experience={props.experience} visible={visible} goToEditor={goToEditor}/>
                    </CardPrimaryAction>
                    :
                    <a href={getHermansUrl()}>
                        <CardPrimaryAction>
                            <CardMedia
                                sixteenByNine
                                style={{
                                    backgroundImage: props.experience.target ? `url(${getUrl(props.experience.target.thumb)}?date=${avoidCacheImage})` : 'url(/images/exp-360.svg)'
                                }}
                            />
                            <ExperienceType experience={props.experience} visible={visible} goToEditor={goToEditor}/>
                        </CardPrimaryAction>
                    </a>
                }
                <CardActions>
                    <div className={'card-experience description-block'}>
                        <div style={{marginLeft: '16px', marginRight: '16px', marginTop: '8px'}}>
                            <Whisper placement="bottom" trigger="hover" speaker={getPopover(props.experience.name)}>
                                <div className="truncateTitle">
                                    <span className={'card-experience title'}>{props.experience.name}</span>
                                </div>
                            </Whisper>
                        </div>

                        <div className="card-experience actions" style={{marginTop: '8px'}}>
                                                        <span className={'card-experience time'}> <Moment locale={locale}
                                                            fromNow>{props.experience.updated_at}</Moment></span>
                            <CardActionIcon icon={
                                <ElipsisMenu onClick={(e) => {
                                    e.stopPropagation()
                                }}>
                                    {/*<div className={'menu-item-dark'}>
                                        <a className={'anchorTag'}
                                           href={getHermansUrl()}>
                                            {t('experiences.actions.open')}
                                        </a>
                                    </div>*/}
                                    {props.experience.target &&
                                    <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'show'})}>
                                        <span>{t('experiences.actions.seeTarget')}</span>
                                    </div>
                                    }
                                    {
                                        props.isDemo || (props.experience.target === undefined && !!props.experience.published.prod) ?
                                            <div className={'menu-item-dark'}
                                                 onClick={handleQRDialog}>
                                                <span>{t('experiences.actions.watchQR')}</span>
                                            </div> : null
                                    }
                                    {!props.isDemo && props.experience.target &&
                                    <div className={'menu-item-dark'}
                                         onClick={handleChangeTarget}
                                    >
                                        <span>{t('experiences.actions.changeTarget')}</span>
                                    </div>}
                                    {/*<div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'edit'}
                                    )}>
                                        <span>{t('pages.campaigns.actions.changeName')}</span>
                                    </div>*/}
                                    {   !props.isDemo &&
                                        <div className={'menu-item-dark'} onClick={handleChangeNameDialog}>
                                            <span>{t('pages.campaigns.actions.changeName')}</span>
                                        </div>
                                    }
                                    {
                                        props.isDemo ?
                                        <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'cloneDemo'})}>
                                            <span>{t('experiences.actions.cloneDemoExp')}</span>
                                        </div>
                                        :
                                        <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'clone'})}>
                                            <span>{t('experiences.actions.cloneExp')}</span>
                                        </div>
                                    }
                                    {!props.isDemo && isPublished(props.experience)  &&
                                    <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'activate'})}>
                                        <span>{props.experience.active ? t('actions.disable') : t('actions.enable')}</span>
                                    </div>
                                    }
                                    {!props.isDemo && props.experience.target === undefined  &&
                                        <div
                                            className={canUseVRMode() && isPublishedToProd() ? 'menu-item-dark': 'menu-item-gray'}
                                            onClick={canUseVRMode() && isPublishedToProd() ? handleMenuClick.bind({key: 'enableVRMode'}) : undefined}
                                            title={
                                                !canUseVRMode() ? t('experiences.vrMode.requireProSubscription')
                                                : isPublishedToProd() ? undefined : t('experiences.vrMode.tooltipPublishRequired')
                                            }
                                        >
                                            <span>{props.experience.vr_mode_compatible ? t('experiences.actions.disableVRMode') : t('experiences.actions.enableVRMode')}</span>
                                            {!canUseVRMode() && <Badge
                                                theme={['onSecondary']}
                                                align="inline"
                                                label="PRO"
                                                style={{
                                                    backgroundColor: '#ff4672', width: '37px',
                                                    'height': '25px'
                                                }}
                                            />}
                                        </div>
                                    }
                                    {!props.isDemo && 
                                        <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'migrate'})}>
                                            <span>{t('experiences.actions.migExp')}</span>
                                        </div>
                                    }
                                    {
                                        isAdmin() ?
                                            <div className={'menu-item-dark'} onClick={openMigrationExperienceAdmin}>
                                                <span>{t('experiences.actions.migExpAdmin')}</span>
                                            </div> : null
                                    }
                                    {
                                        !props.isDemo && props.experience.is_ad &&
                                        <div className={'menu-item-dark'}  onClick={handleMenuClick.bind({key: 'changeLoading'})} >
                                            <span>{t('actions.changeLoading')}</span>
                                        </div> 
                                    }
                                    {
                                        !props.isDemo && !isFreelance() && !isFreelancePaid() &&
                                        <div className={'menu-item-dark'}  onClick={handleMenuClick.bind({key: 'experienceAd'})} >
                                            <span>{props.experience.is_ad ? t('actions.converToNormal') : t('actions.converToAd') }</span>
                                        </div>
                                    }
                                    <CheckPermission
                                        component={!props.isDemo && (
                                            <div className={`menu-item-dark`} onClick={handleMenuClick.bind({key: 'reports'})}>
                                                <span>{t('experiences.actions.seeReports')}</span>
                                            </div>
                                        )}
                                        groups={[
                                            userGroupsObject.CamonappUsers,
                                            userGroupsObject.CamonappAdminUsers,
                                            userGroupsObject.CamonappSalesUsers,
                                            userGroupsObject.CompanyUsers,
                                            userGroupsObject.FreelanceUsers,
                                            userGroupsObject.FreelancePaidUsers,
                                        ]}
                                    />
                                    {!props.isDemo && 
                                    <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'delete'})}>
                                        <span>{t('actions.delete')}</span>
                                    </div>
                                    }
                                </ElipsisMenu>
                            }/>
                        </div>
                    </div>
                </CardActions>
                {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
                {exp &&
                <Modal {...cmpDataObj} onClose={() => {
                    setExp(false)
                }}/>
                }
            </Card>
            }
        </CardWrapper>

    )
});

export default ExperienceCard;
